import Dashboard from '../Dashboard';
import { AreaCreate, AreaEdit, AreaList } from './areas';
import { ClientCreate, ClientEdit, ClientList } from './clients';
import { CurrencyCreate, CurrencyEdit, CurrencyList } from './currencies';
import {
  ExchangeRateCreate,
  ExchangeRateEdit,
  ExchangeRateList
} from './exchange-rates';
import {
  InvoiceProjectionCreate,
  InvoiceProjectionEdit,
  InvoiceProjectionList,
  InvoiceProjectionShow
} from './invoice-projections';
import { InvoiceCreate, InvoiceList, InvoiceShow } from './invoices';
import {
  MonthlyExpenseProjectionCreate,
  MonthlyExpenseProjectionEdit,
  MonthlyExpenseProjectionList
} from './monthly-expense-projections';
import {
  MonthlyExpenseCreate,
  MonthlyExpenseEdit,
  MonthlyExpenseList
} from './monthly-expenses';
import {
  ProjectTechnologiesCreate,
  ProjectTechnologiesEdit,
  ProjectTechnologiesList,
  ProjectTechnologiesShow
} from './project-technologies';
import {
  ProjectCreate,
  ProjectEdit,
  ProjectList,
  ProjectShow
} from './projects';
import {
  ProjectTypeCreate,
  ProjectTypeEdit,
  ProjectTypeList
} from './projectTypes';
import {
  ResourceTechnologiesCreate,
  ResourceTechnologiesEdit,
  ResourceTechnologiesList,
  ResourceTechnologiesShow
} from './resource-technologies';
import { ResourceCreate, ResourceEdit, ResourceList } from './resources';
import { RoleCreate, RoleEdit, RoleList } from './roles';
import { SeniorityCreate, SeniorityEdit, SeniorityList } from './seniorities';
import {
  SpentHoursTypesCreate,
  SpentHoursTypesEdit,
  SpentHoursTypesList
} from './spent-hours-types';
import {
  TechnologiesCreate,
  TechnologiesEdit,
  TechnologiesList,
  TechnologiesShow
} from './technologies';
import {
  TechnologyCategoriesCreate,
  TechnologyCategoriesEdit,
  TechnologyCategoriesList,
  TechnologyCategoriesShow
} from './technology-categories';
import {
  UserHoursRegisterCreate,
  UserHoursRegisterEdit,
  UserHoursRegisterList
} from './user-hours-register';
import { UserCreate, UserEdit, UserList } from './users';

const User = {
  Dashboard,
  AreaCreate,
  AreaEdit,
  AreaList,
  CurrencyList,
  CurrencyEdit,
  CurrencyCreate,
  RoleList,
  RoleEdit,
  RoleCreate,
  UserList,
  UserEdit,
  UserCreate,
  ClientList,
  ClientEdit,
  ClientCreate,
  ExchangeRateList,
  ExchangeRateEdit,
  ExchangeRateCreate,
  SeniorityCreate,
  SeniorityEdit,
  SeniorityList,
  InvoiceCreate,
  InvoiceShow,
  InvoiceList,
  MonthlyExpenseList,
  MonthlyExpenseEdit,
  MonthlyExpenseCreate,
  ResourceCreate,
  ResourceEdit,
  ResourceList,
  ProjectList,
  ProjectEdit,
  ProjectCreate,
  ProjectShow,
  ProjectTypeCreate,
  ProjectTypeEdit,
  ProjectTypeList,
  MonthlyExpenseProjectionCreate,
  MonthlyExpenseProjectionEdit,
  MonthlyExpenseProjectionList,
  InvoiceProjectionCreate,
  InvoiceProjectionEdit,
  InvoiceProjectionList,
  InvoiceProjectionShow,
  UserHoursRegisterCreate,
  UserHoursRegisterEdit,
  UserHoursRegisterList,
  SpentHoursTypesCreate,
  SpentHoursTypesEdit,
  SpentHoursTypesList,
  ProjectTechnologiesList,
  ProjectTechnologiesCreate,
  ProjectTechnologiesEdit,
  ProjectTechnologiesShow,
  TechnologyCategoriesCreate,
  TechnologyCategoriesEdit,
  TechnologyCategoriesList,
  TechnologyCategoriesShow,
  TechnologiesList,
  TechnologiesCreate,
  TechnologiesEdit,
  TechnologiesShow,
  ResourceTechnologiesCreate,
  ResourceTechnologiesEdit,
  ResourceTechnologiesList,
  ResourceTechnologiesShow
};

export default User;
