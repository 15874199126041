/* eslint-disable no-shadow */
/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import { crudCreate, SaveButton, Toolbar } from 'react-admin';
import { connect } from 'react-redux';

const saveMonthlyExpense = (values, basePath, redirectTo) => {
  const period = new Date();
  period.setFullYear(values.year);
  period.setMonth(values.month);
  period.setDate('01');
  const monthlyExpense = {
    period,
    amount: values.amount
  };
  return crudCreate('monthly-expenses', monthlyExpense, basePath, redirectTo);
};

class SaveMonthlyExpenseButtonView extends Component {
  handleClick = () => {
    const { basePath, handleSubmit, redirect, saveMonthlyExpense } = this.props;
    return handleSubmit(values => {
      saveMonthlyExpense(values, basePath, redirect);
    });
  };

  render() {
    const {
      handleSubmitWithRedirect,
      saveMonthlyExpense,
      ...props
    } = this.props;

    return (
      <SaveButton handleSubmitWithRedirect={this.handleClick} {...props} />
    );
  }
}

const SaveMonthlyExpenseButton = connect(undefined, {
  saveMonthlyExpense
})(SaveMonthlyExpenseButtonView);

const SaveMonthlyExpensesToolbar = props => (
  <Toolbar {...props}>
    <SaveMonthlyExpenseButton {...props} />
  </Toolbar>
);

export default SaveMonthlyExpensesToolbar;
