/* eslint-disable no-shadow */
/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import { crudCreate, SaveButton, Toolbar } from 'react-admin';
import { connect } from 'react-redux';

const saveMonthlyExpenseProjection = (values, basePath, redirectTo) => {
  const period = new Date();
  period.setFullYear(values.year);
  period.setMonth(values.month);
  period.setDate('01');
  const monthlyExpenseProjection = {
    period,
    amount: values.amount
  };
  return crudCreate(
    'monthly-expense-projections',
    monthlyExpenseProjection,
    basePath,
    redirectTo
  );
};

class SaveMonthlyExpenseProjectionButtonView extends Component {
  handleClick = () => {
    const {
      basePath,
      handleSubmit,
      redirect,
      saveMonthlyExpenseProjection
    } = this.props;
    return handleSubmit(values => {
      saveMonthlyExpenseProjection(values, basePath, redirect);
    });
  };

  render() {
    const {
      handleSubmitWithRedirect,
      saveMonthlyExpenseProjection,
      ...props
    } = this.props;

    return (
      <SaveButton handleSubmitWithRedirect={this.handleClick} {...props} />
    );
  }
}

const SaveMonthlyExpenseProjectionButton = connect(undefined, {
  saveMonthlyExpenseProjection
})(SaveMonthlyExpenseProjectionButtonView);

const SaveMonthlyExpenseProjectionsToolbar = props => (
  <Toolbar {...props}>
    <SaveMonthlyExpenseProjectionButton {...props} />
  </Toolbar>
);

export default SaveMonthlyExpenseProjectionsToolbar;
