import ActionTypes from '../constants/invoice';

export function loadInvoiceDetail(invoiceDetail) {
  return {
    type: ActionTypes.LOAD_INVOICE_DETAIL,
    payload: invoiceDetail
  };
}

export function updateInvoiceDetails({ resources, details }) {
  return {
    type: ActionTypes.UPDATE_INVOICE_DETAILS,
    payload: { resources, details }
  };
}

export function resetInvoiceDetails() {
  return {
    type: ActionTypes.RESET_INVOICE_DETAILS
  };
}

export function loadInvoiceDate(invoiceDate) {
  return {
    type: ActionTypes.LOAD_INVOICE_DATE,
    payload: invoiceDate
  };
}

export function resetInvoiceDate() {
  return {
    type: ActionTypes.RESET_INVOICE_DATE
  };
}
