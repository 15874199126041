import { format as formatFns, parse as parseFns } from 'date-fns';

const rate = 10000;

const format = v => (v ? v / rate : v);
const parse = v => (v ? v * rate : v);
const thousands = v => (v ? v / 1000 : v);

// Convert number to string with fixed 2 decimals
const show2Decimals = v => (v ? v.toFixed(2) : v);

// Format date to yyyy-MM-dd from date
const formatDate = date =>
  date ? new Date(date).toISOString().slice(0, 10) : null;

// Create date type with first day of month from year, month
const fromYYYYMMToDate = (year, month) => {
  const date = new Date();
  date.setUTCFullYear(year, month, 1);
  date.setUTCHours(0, 0, 0, 0);
  return date;
};

// Create date type from dateStr formated with yyyy-MM-dd
const parseFromYYYYMMDD = dateStr =>
  parseFns(dateStr, 'yyyy-MM-dd', new Date());

// Format date to yyyy-MM string, from yyyy-MM-dd string
const formatDateYYYYMM = dateStr =>
  formatFns(parseFromYYYYMMDD(dateStr), 'yyyy-MM');
// Replace dashboard data x with its timeX property so it can be represented as a timeline
const dataToTimeline = data =>
  data.map(row => ({
    ...row,
    data: row.data.map(elem => ({ ...elem, x: elem.timeX }))
  }));

const conversions = {
  format,
  parse,
  thousands,
  show2Decimals,
  formatDate,
  fromYYYYMMToDate,
  formatDateYYYYMM,
  parseFromYYYYMMDD,
  dataToTimeline
};

export default conversions;
