import PropTypes from 'prop-types';
import React from 'react';
import {
  ChipField,
  Create,
  Datagrid,
  Edit,
  EditButton,
  Filter,
  GET_ONE,
  List,
  maxValue,
  minValue,
  NumberInput,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  Show,
  SimpleForm,
  TextField,
  withTranslate
} from 'react-admin';

import { DataProvider } from '../providers';

const validateTechnologyPersentage = translate => [
  minValue(
    1,
    translate('resources.resource-technologies.errors.technologyPercentage')
  ),
  maxValue(
    5,
    translate('resources.resource-technologies.errors.technologyPercentage')
  )
];

class ResourceTechnologiesTitle extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      resourceName: null
    };
  }

  async componentDidMount() {
    const { record } = this.props;
    const resourceData = await DataProvider(GET_ONE, 'users', {
      id: record.resource
    });
    this.setState({ resourceName: resourceData.data.name });
  }

  render() {
    const { resourceName } = this.state;
    return <span>{resourceName}</span>;
  }
}

const ResourceTechnologiesFilter = props => (
  <Filter {...props}>
    <ReferenceInput source="resource" reference="users" allowempty alwaysOn>
      <SelectInput optionText="name" />
    </ReferenceInput>
    <ReferenceInput
      source="technology"
      reference="technologies"
      allowempty
      alwaysOn
    >
      <SelectInput optionText="name" />
    </ReferenceInput>
    <NumberInput source="technologyPercentage" allowempty alwaysOn />
  </Filter>
);

export const ResourceTechnologiesList = props => {
  return (
    <List {...props} filters={<ResourceTechnologiesFilter />}>
      <Datagrid rowClick="show">
        <ReferenceField source="resource" reference="users" linkType={false}>
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField
          source="technology"
          reference="technologies"
          linkType={false}
        >
          <ChipField source="name" color="primary" />
        </ReferenceField>
        <TextField source="technologyPercentage" />
        <EditButton />
      </Datagrid>
    </List>
  );
};

export const ResourceTechnologiesCreate = withTranslate(
  ({ translate, ...props }) => (
    <Create {...props}>
      <SimpleForm {...props} redirect="list">
        <ReferenceInput source="resource" reference="users">
          <SelectInput optionText="name" />
        </ReferenceInput>
        <ReferenceInput source="technology" reference="technologies">
          <SelectInput optionText="name" />
        </ReferenceInput>
        <NumberInput
          source="technologyPercentage"
          validate={validateTechnologyPersentage(translate)}
        />
      </SimpleForm>
    </Create>
  )
);

export const ResourceTechnologiesShow = props => {
  return (
    <Show {...props} title={<ResourceTechnologiesTitle />}>
      <SimpleForm {...props} redirect="list">
        <ReferenceField source="resource" reference="users" linkType={false}>
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField
          source="technology"
          reference="technologies"
          linkType={false}
        >
          <ChipField source="name" color="primary" />
        </ReferenceField>
        <TextField source="technologyPercentage" />
      </SimpleForm>
    </Show>
  );
};

export const ResourceTechnologiesEdit = withTranslate(
  ({ translate, ...props }) => (
    <Edit {...props} title={<ResourceTechnologiesTitle />}>
      <SimpleForm {...props} reidrect="show">
        <ReferenceInput source="resource" reference="users">
          <SelectInput optionText="name" />
        </ReferenceInput>
        <ReferenceInput source="technology" reference="technologies">
          <SelectInput optionText="name" />
        </ReferenceInput>
        <NumberInput
          source="technologyPercentage"
          validate={validateTechnologyPersentage(translate)}
        />
      </SimpleForm>
    </Edit>
  )
);

ResourceTechnologiesTitle.propTypes = {
  record: PropTypes.instanceOf(Object).isRequired
};
