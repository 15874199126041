import React from 'react';
import {
  Create,
  Datagrid,
  Edit,
  EditButton,
  Filter,
  List,
  SearchInput,
  SimpleForm,
  TextField,
  TextInput
} from 'react-admin';

import EditingTitle from '../utils/editingTitle';

const CurrencyFilter = props => (
  <Filter {...props}>
    <SearchInput source="q" alwaysOn />
  </Filter>
);

const validateCurrencyCreation = values => {
  const errors = {};
  if (!values.name) {
    errors.name = ['The name is required'];
  }
  return errors;
};

export const CurrencyList = props => (
  <List filters={<CurrencyFilter />} {...props}>
    <Datagrid rowClick="edit">
      <TextField source="name" />
      <EditButton />
    </Datagrid>
  </List>
);

export const CurrencyEdit = props => (
  <Edit title={<EditingTitle.ForName {...props} />} {...props}>
    <SimpleForm>
      <TextInput source="name" />
    </SimpleForm>
  </Edit>
);

export const CurrencyCreate = props => (
  <Create {...props}>
    <SimpleForm validate={validateCurrencyCreation} redirect="/currencies">
      <TextInput source="name" />
    </SimpleForm>
  </Create>
);
