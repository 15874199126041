const months = [
  { id: 0, name: 'calendarize.months.jan' },
  { id: 1, name: 'calendarize.months.feb' },
  { id: 2, name: 'calendarize.months.mar' },
  { id: 3, name: 'calendarize.months.apr' },
  { id: 4, name: 'calendarize.months.may' },
  { id: 5, name: 'calendarize.months.jun' },
  { id: 6, name: 'calendarize.months.jul' },
  { id: 7, name: 'calendarize.months.aug' },
  { id: 8, name: 'calendarize.months.sep' },
  { id: 9, name: 'calendarize.months.oct' },
  { id: 10, name: 'calendarize.months.nov' },
  { id: 11, name: 'calendarize.months.dec' }
];

const generateYears = () => {
  const currentYear = new Date().getFullYear();
  const generatedYears = [];
  for (let i = -1; i <= 1; i += 1) {
    const yearOption = {
      id: currentYear + i,
      name: (currentYear + i).toString()
    };
    generatedYears.push(yearOption);
  }
  return generatedYears;
};

const years = generateYears();

export { months, years };
