import React from 'react';
import {
  BooleanField,
  BooleanInput,
  Create,
  Datagrid,
  Edit,
  EditButton,
  Filter,
  List,
  SearchInput,
  SimpleForm,
  TextField,
  TextInput
} from 'react-admin';

import EditingTitle from '../utils/editingTitle';

const ProjectTypeFilter = props => (
  <Filter {...props}>
    <SearchInput source="q" alwaysOn />
  </Filter>
);

const validateProjectTypeCreation = values => {
  const errors = {};
  if (!values.name) {
    errors.name = ['The name is required'];
  }
  return errors;
};

export const ProjectTypeList = props => (
  <List filters={<ProjectTypeFilter />} {...props}>
    <Datagrid rowClick="edit">
      <TextField source="name" />
      <BooleanField source="allowSettingTotalAmount" />
      <BooleanField source="allowSettingResourcesTime" />
      <BooleanField source="calculateTotalAmountWithResourcesTime" />
      <BooleanField source="rateRequired" />
      <EditButton />
    </Datagrid>
  </List>
);

export const ProjectTypeEdit = props => (
  <Edit title={<EditingTitle.ForName {...props} />} {...props}>
    <SimpleForm>
      <TextInput source="name" />
      <BooleanInput source="allowSettingTotalAmount" />
      <BooleanInput source="allowSettingResourcesTime" />
      <BooleanInput source="calculateTotalAmountWithResourcesTime" />
      <BooleanInput source="rateRequired" />
    </SimpleForm>
  </Edit>
);

export const ProjectTypeCreate = props => (
  <Create {...props}>
    <SimpleForm validate={validateProjectTypeCreation} redirect="/projectTypes">
      <TextInput source="name" />
      <BooleanInput source="allowSettingTotalAmount" />
      <BooleanInput source="allowSettingResourcesTime" />
      <BooleanInput source="calculateTotalAmountWithResourcesTime" />
      <BooleanInput source="rateRequired" />
    </SimpleForm>
  </Create>
);
