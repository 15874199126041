import React from 'react';
import {
  Create,
  Datagrid,
  Edit,
  EditButton,
  Filter,
  List,
  SearchInput,
  SimpleForm,
  TextField,
  TextInput
} from 'react-admin';

import EditingTitle from '../utils/editingTitle';

const ClientFilter = props => (
  <Filter {...props}>
    <SearchInput source="q" alwaysOn />
  </Filter>
);

const validateClientCreation = values => {
  const errors = {};
  if (!values.name) {
    errors.name = ['The name is required'];
  }
  return errors;
};

export const ClientList = props => (
  <List filters={<ClientFilter />} {...props}>
    <Datagrid rowClick="edit">
      <TextField source="name" />
      <EditButton />
    </Datagrid>
  </List>
);

export const ClientEdit = props => (
  <Edit title={<EditingTitle.ForName {...props} />} {...props}>
    <SimpleForm>
      <TextInput source="name" />
    </SimpleForm>
  </Edit>
);

export const ClientCreate = props => (
  <Create {...props}>
    <SimpleForm validate={validateClientCreation} redirect="/clients">
      <TextInput source="name" />
    </SimpleForm>
  </Create>
);
