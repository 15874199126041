import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import LockIcon from '@material-ui/icons/LockOutline';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import {
  Notification,
  translate as newTranslate,
  userLogin
} from 'react-admin';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { Field, propTypes, reduxForm } from 'redux-form';

const mediaUrl = `url(images/backgroundLogin.svg)`;

const styles = theme => ({
  main: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    alignItems: 'center',
    justifyContent: 'flex-start',
    background: mediaUrl,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundColor: '#00c79a',
    backgroundPosition: '60%'
  },
  button: {
    backgroundColor: '#00c79a',
    color: '#fafafa'
  },
  card: {
    minWidth: 400,
    marginTop: '23%'
  },
  avatar: {
    margin: '1em',
    display: 'flex',
    justifyContent: 'center'
  },
  icon: {
    backgroundColor: theme.palette.grey[500]
  },
  form: {
    padding: '0 1em 1em 1em'
  },
  input: {
    marginTop: '1em'
  },
  actions: {
    padding: '0 1em 1em 1em'
  }
});

const renderInput = ({
  meta: { touched, error } = {}, // eslint-disable-line react/prop-types
  input: { ...inputProps }, // eslint-disable-line react/prop-types
  ...props
}) => (
  <TextField
    error={!!(touched && error)}
    helperText={touched && error}
    {...inputProps}
    {...props}
    fullWidth
  />
);

class Login extends Component {
  login = auth => {
    const { location, userLogin } = this.props; // eslint-disable-line no-shadow
    userLogin(auth, location.state ? location.state.nextPathname : '/');
  };

  render() {
    const { classes, handleSubmit, isLoading, translate } = this.props;
    return (
      <div className={classes.main}>
        <Card className={classes.card}>
          <div className={classes.avatar}>
            <Avatar className={classes.icon}>
              <LockIcon />
            </Avatar>
          </div>
          <form onSubmit={handleSubmit(this.login)}>
            <div className={classes.form}>
              <div className={classes.input}>
                <Field
                  name="username"
                  component={renderInput}
                  label={translate('pos.login.fields.email')}
                  disabled={isLoading}
                />
              </div>
              <div className={classes.input}>
                <Field
                  name="password"
                  component={renderInput}
                  label={translate('pos.login.fields.password')}
                  type="password"
                  disabled={isLoading}
                />
              </div>
            </div>
            <CardActions className={classes.actions}>
              <Button
                variant="raised"
                type="submit"
                disabled={isLoading}
                className={classes.button}
                fullWidth
              >
                {isLoading && <CircularProgress size={25} thickness={2} />}
                {translate('pos.login.fields.signIn')}
              </Button>
            </CardActions>
          </form>
        </Card>
        <Notification />
      </div>
    );
  }
}

Login.propTypes = {
  ...propTypes,
  translate: PropTypes.func.isRequired,
  userLogin: PropTypes.func.isRequired
};

const mapStateToProps = state => ({ isLoading: state.admin.loading > 0 });

const enhance = compose(
  newTranslate,
  reduxForm({
    form: 'signIn',
    validate: values => {
      const errors = {};
      if (!values.username) {
        errors.username = newTranslate('ra.validation.required');
      }
      if (!values.password) {
        errors.password = newTranslate('ra.validation.required');
      }
      return errors;
    }
  }),
  connect(mapStateToProps, { userLogin }),
  withStyles(styles)
);

export default enhance(Login);
