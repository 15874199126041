import englishMessages from 'ra-language-english';

export default {
  ...englishMessages,
  pos: {
    search: 'Search',
    dashboard: {
      welcome: {
        title: 'Welcome to Cambá Admin App',
        subheader:
          'App to manage our projects occupation and income projection.',
        actions: 'Date Range search',
        month: 'Months',
        barChart: {
          status: 'Occupation status',
          associated: 'Y: Associated'
        },
        lineChart: {
          salesExpenses: 'Sales / Expenses',
          billing: 'Billing (Expressed in thousands)',
          Ventas: 'Sales',
          Gastos: 'Expenses',
          'Ventas Proyectadas': 'Proyected Sales',
          'Gastos Proyectados': 'Proyected Expenses'
        },
        pieChart: {
          revenueCustomer: 'Invoices: Revenue per customer',
          subheader: 'Expressed in thousands'
        }
      },
      noData: 'There is no data found for the selected period'
    },
    login: {
      fields: {
        email: 'Email',
        password: 'Password',
        signIn: 'Sign In'
      }
    }
  },
  calendarize: {
    months: {
      jan: 'January',
      feb: 'February',
      mar: 'March',
      apr: 'April',
      may: 'May',
      jun: 'June',
      jul: 'July',
      aug: 'August',
      sep: 'September',
      oct: 'October',
      nov: 'November',
      dec: 'December'
    }
  },
  components: {
    emptyRow: {
      noResources: 'The selected project does not have assigned workers',
      noInvoiceDetails: 'The current invoice contains no details'
    }
  },
  resources: {
    areas: {
      name: 'Area |||| Areas',
      fields: {
        name: 'Name',
        includeInOccupancyChart: 'Include user in occupancy chart'
      }
    },
    clients: {
      name: 'Client |||| Clients',
      fields: {
        name: 'Name'
      }
    },
    resources: {
      name: 'Worker |||| Workers',
      fields: {
        project: 'Project',
        role: 'Role',
        seniority: 'Seniority',
        user: 'User',
        rate: 'Rate',
        startingDate: 'Starting Date',
        endingDate: 'Ending Date'
      },
      notification: {
        resource_period_error:
          'The worker period must be inside the project period.'
      }
    },
    users: {
      name: 'User |||| Users',
      fields: {
        name: 'Name',
        email: 'Email',
        administrator: 'Administrator'
      }
    },
    seniorities: {
      name: 'Seniority |||| Seniorities',
      fields: {
        name: 'Name'
      }
    },
    currencies: {
      name: 'Currency |||| Currencies',
      fields: {
        name: 'Name'
      }
    },
    roles: {
      name: 'Rol |||| Roles',
      fields: {
        name: 'Name'
      }
    },
    'exchange-rates': {
      name: 'Exchange Rate |||| Exchange Rates',
      fields: {
        currency: 'Currency',
        value: 'Value',
        date: 'Date'
      }
    },
    'monthly-expenses': {
      name: 'Monthly Expense |||| Monthly Expenses',
      fields: {
        period: 'Period',
        amount: 'Amount',
        month: 'Month',
        year: 'Year'
      }
    },
    invoices: {
      name: 'Invoice |||| Invoices',
      fields: {
        identifier: 'Identifier',
        period: 'Period',
        month: 'Month',
        year: 'Year',
        currency: 'Currency',
        amount: 'Amount',
        invoiceDetails: 'Invoice details',
        resource: 'Worker',
        role: 'Role',
        seniority: 'Seniority',
        exchangeRate: 'Exchange Rate',
        hours: 'Hours',
        project: 'Project',
        client: 'Client'
      },
      outOfPeriod: 'Out of period',
      validation: {
        required: 'The %{source} is required',
        greater: 'The %{source} should be greater than 0 or must be selected.'
      }
    },
    projects: {
      name: 'Project |||| Projects',
      fields: {
        name: 'Name',
        client: 'Client',
        currency: 'Currency',
        startingDate: 'Starting Date',
        endingDate: 'Ending Date',
        projectType: 'Project Type',
        endingVarianceDate: 'Variance Date'
      },
      labels: {
        addResource: 'Add a worker',
        resources: 'Workers'
      },
      validation: {
        projectRange:
          'The project takes place between the dates %{startingDate} and %{endingDate}. The selected date must be within that period',
        range: {
          startingDate: 'Starting date must be less than ending date.',
          endingDate: 'Ending date must be greater than starting date.'
        }
      }
    },
    projectTypes: {
      name: 'Project Type |||| Project Types',
      fields: {
        name: 'Name',
        allowSettingTotalAmount: 'Allow setting invoice total amount',
        allowSettingResourcesTime: 'Allow setting workers time',
        calculateTotalAmountWithResourcesTime:
          'Calculate total amount with worker time',
        rateRequired: 'Rate required for workers creation'
      }
    },
    'invoice-projections': {
      name: 'Invoice Projections |||| Invoices Projections',
      fields: {
        identifier: 'Identifier',
        period: 'Period',
        month: 'Month',
        year: 'Year',
        project: 'Project',
        currency: 'Currency',
        amount: 'Amount',
        invoiceDetails: 'Invoiced Details',
        resource: 'Socie',
        role: 'Role',
        seniority: 'Seniority',
        exchangeRate: 'Exchange Rate',
        hours: 'Hours'
      }
    },
    'user-hours-register': {
      name: 'Hours Registry |||| Hours Registers',
      fields: {
        user: 'User',
        project: 'Project',
        type: 'Hours Type',
        date: 'Date',
        startingDate: 'Starting Date',
        startingDate_gte: 'Starting Date',
        startingDate_lte: 'Starting Date Lesser Than',
        endingDate: 'Ending Date',
        endingDate_gte: 'Ending Date Greater Than',
        endingDate_lte: 'Ending Date',
        hours: 'Hours',
        description: 'Description'
      },
      errors: {
        hoursRequired: 'The hours are required',
        dateRequired: 'The date is required',
        typeRequired: 'The type is required',
        hoursGreaterThan0: 'The hours ammount should be greater than 0',
        hoursLessThan24: 'The hours ammount should be less than 24 or equal',
        startingAfterEnding: 'Starting date must be before than ending date',
        endingBeforeStarting: 'Ending date must be after than starting date'
      }
    },
    'spent-hours-types': {
      name: 'Hours type |||| Hours Types',
      fields: {
        name: 'Name'
      }
    },
    'project-technologies': {
      name: 'A Project Technologies |||| Projects Technologies',
      fields: {
        project: 'Project',
        technologies: 'Technologies'
      }
    },
    'technology-categories': {
      name: 'Technology Category |||| Technology Categories',
      fields: {
        name: 'Category name'
      }
    },
    technologies: {
      name: 'Technology |||| Technologies',
      fields: {
        name: 'Technology Name',
        category: 'Technology Category',
        related: 'Related Technologies'
      }
    },
    'resource-technologies': {
      name: 'Worker Technology |||| Worker Technologies',
      fields: {
        resource: 'Worker',
        technology: 'Technology',
        technologyPercentage: 'Technology Percentage rate'
      },
      erros: {
        technologyPercentageRange:
          'The technology percentage value must be between 1 to 5'
      }
    }
  }
};
