/* eslint-disable react/prop-types */
import CardActions from '@material-ui/core/CardActions';
import React from 'react';
import { ListButton, RefreshButton } from 'react-admin';

import AddNewResource from './add-new-resource/AddNewResource';

const ProjectShowActions = ({ basePath, data }) => (
  <CardActions>
    <ListButton basePath={basePath} />
    <RefreshButton />
    <AddNewResource record={data} />
  </CardActions>
);

export default ProjectShowActions;
