import React from 'react';
import {
  BooleanInput,
  Create,
  Datagrid,
  Edit,
  EditButton,
  EmailField,
  List,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextField,
  TextInput
} from 'react-admin';

import EditingTitle from '../utils/editingTitle';

const validateUserCreation = values => {
  const errors = {};
  if (!values.name) {
    errors.name = ['The name is required'];
  }
  if (!values.email) {
    errors.email = ['The email is required'];
  }
  if (!values.password) {
    errors.password = ['The password is required'];
  }
  return errors;
};

export const UserList = props => (
  <List {...props}>
    <Datagrid rowClick="edit">
      <TextField source="name" />
      <EmailField source="email" />
      <EditButton />
    </Datagrid>
  </List>
);

export const UserEdit = props => (
  <Edit title={<EditingTitle.ForEmail {...props} />} {...props}>
    <SimpleForm redirect="/users">
      <TextInput source="name" />
      <TextInput label="Dirección de email" source="email" type="email" />
      <TextInput label="Password" source="password" type="password" />
      <BooleanInput source="administrator" />
      <ReferenceInput source="area" reference="areas">
        <SelectInput source="name" />
      </ReferenceInput>
    </SimpleForm>
  </Edit>
);

export const UserCreate = props => (
  <Create {...props}>
    <SimpleForm validate={validateUserCreation} redirect="/users">
      <TextInput source="name" />
      <TextInput label="Dirección de email" source="email" type="email" />
      <TextInput label="Password" source="password" type="password" />
      <BooleanInput source="administrator" />
      <ReferenceInput source="area" reference="areas">
        <SelectInput source="name" />
      </ReferenceInput>
    </SimpleForm>
  </Create>
);
