import decodeJwt from 'jwt-decode';
import { authClient, restClient } from 'ra-data-feathers';

import feathersClient from './feathersClient';

const authClientOptions = {
  storageKey: 'feathers-jwt',
  authenticate: { strategy: 'local' },
  usernameField: 'email',
  passwordField: 'password',
  permissionsKey: 'isAdministrator', // key para usar en el localStorage
  permissionsField: 'administrator'
};

const options = { id: '_id', usePatch: true };
const DataProvider = restClient(feathersClient, options);
const AuthProvider = authClient(feathersClient, authClientOptions);

const getJwtData = () => {
  const jwtToken = localStorage.getItem(authClientOptions.storageKey);
  return decodeJwt(jwtToken);
};

const clearJwtData = () => {
  localStorage.clear();
};

export { DataProvider, AuthProvider, getJwtData, clearJwtData };
