import spanishMessages from '@blackbox-vision/ra-language-spanish';
import RoleIcon from '@material-ui/icons/Accessibility';
import SpentHoursTypesIcon from '@material-ui/icons/AddAlarm';
import CurrencyIcon from '@material-ui/icons/Casino';
import ClassIcon from '@material-ui/icons/Class';
import ClientIcon from '@material-ui/icons/Contacts';
import UserIcon from '@material-ui/icons/Group';
import UserHourRegisterIcon from '@material-ui/icons/History';
import MemoryIcon from '@material-ui/icons/Memory';
import InvoiceIcon from '@material-ui/icons/MonetizationOn';
import ExpensesIcon from '@material-ui/icons/MoneyOff';
import StorageIcon from '@material-ui/icons/Storage';
import SeniorityIcon from '@material-ui/icons/ThumbsUpDown';
import WorkIcon from '@material-ui/icons/Work';
import englishMessages from 'ra-language-english';
import React from 'react';
import { Admin, resolveBrowserLocale, Resource } from 'react-admin';

import Dashboard from './Dashboard';
import * as domainMessages from './i18n';
import MyLayout from './Layout';
import LoginPage from './Login';
import { AuthProvider, DataProvider } from './providers';
import User from './services';
import invoice from './store/reducers/invoiceReducer';

const messages = {
  es: { ...spanishMessages, ...domainMessages.es },
  en: { ...englishMessages, ...domainMessages.en }
};
const i18nProvider = locale => messages[locale];
const adminTitle = 'Okupas';
const App = () => (
  <Admin
    title={adminTitle}
    appLayout={MyLayout}
    loginPage={LoginPage}
    dashboard={Dashboard}
    authProvider={AuthProvider}
    dataProvider={DataProvider}
    locale={resolveBrowserLocale()}
    i18nProvider={i18nProvider}
    customReducers={{ invoice }}
  >
    {isAdministrator => {
      return [
        isAdministrator && (
          <Resource
            name="clients"
            options={{ label: 'Clients' }}
            list={Array.isArray(isAdministrator) ? '' : User.ClientList}
            edit={Array.isArray(isAdministrator) ? '' : User.ClientEdit}
            create={Array.isArray(isAdministrator) ? '' : User.ClientCreate}
            icon={ClientIcon}
          />
        ),
        isAdministrator && (
          <Resource
            name="projects"
            options={{ label: 'Projects' }}
            list={Array.isArray(isAdministrator) ? '' : User.ProjectList}
            edit={Array.isArray(isAdministrator) ? '' : User.ProjectEdit}
            create={Array.isArray(isAdministrator) ? '' : User.ProjectCreate}
            show={Array.isArray(isAdministrator) ? '' : User.ProjectShow}
            icon={WorkIcon}
          />
        ),
        isAdministrator && (
          <Resource
            name="resources"
            options={{ label: 'Resources' }}
            list={Array.isArray(isAdministrator) ? '' : User.ResourceList}
            edit={Array.isArray(isAdministrator) ? '' : User.ResourceEdit}
            create={Array.isArray(isAdministrator) ? '' : User.ResourceCreate}
            icon={StorageIcon}
          />
        ),
        isAdministrator && (
          <Resource
            name="invoice-projections"
            options={{ label: 'Invoice Projections' }}
            list={
              Array.isArray(isAdministrator) ? '' : User.InvoiceProjectionList
            }
            edit={User.InvoiceProjectionEdit}
            create={User.InvoiceProjectionCreate}
            show={User.InvoiceProjectionShow}
            icon={InvoiceIcon}
          />
        ),
        isAdministrator && (
          <Resource
            name="exchange-rates"
            options={{ label: 'Exchange Rates' }}
            list={Array.isArray(isAdministrator) ? '' : User.ExchangeRateList}
            edit={User.ExchangeRateEdit}
            create={User.ExchangeRateCreate}
            icon={CurrencyIcon}
          />
        ),
        isAdministrator && (
          <Resource
            name="monthly-expense-projections"
            options={{ label: 'Monthly Expense Projections' }}
            list={
              Array.isArray(isAdministrator)
                ? ''
                : User.MonthlyExpenseProjectionList
            }
            edit={
              Array.isArray(isAdministrator)
                ? ''
                : User.MonthlyExpenseProjectionEdit
            }
            create={
              Array.isArray(isAdministrator)
                ? ''
                : User.MonthlyExpenseProjectionCreate
            }
            icon={ExpensesIcon}
          />
        ),
        isAdministrator && (
          <Resource
            name="monthly-expenses"
            options={{ label: 'Monthly Expenses' }}
            list={Array.isArray(isAdministrator) ? '' : User.MonthlyExpenseList}
            edit={Array.isArray(isAdministrator) ? '' : User.MonthlyExpenseEdit}
            create={
              Array.isArray(isAdministrator) ? '' : User.MonthlyExpenseCreate
            }
            icon={ExpensesIcon}
          />
        ),
        isAdministrator && (
          <Resource
            name="invoices"
            options={{ label: 'Invoices' }}
            list={Array.isArray(isAdministrator) ? '' : User.InvoiceList}
            show={User.InvoiceShow}
            create={User.InvoiceCreate}
            icon={InvoiceIcon}
          />
        ),
        isAdministrator && (
          <Resource
            name="projectTypes"
            options={{ label: 'Project Types' }}
            list={Array.isArray(isAdministrator) ? '' : User.ProjectTypeList}
            edit={User.ProjectTypeEdit}
            create={User.ProjectTypeCreate}
            icon={ClassIcon}
          />
        ),
        isAdministrator && (
          <Resource
            name="users"
            options={{ label: 'Users' }}
            list={Array.isArray(isAdministrator) ? '' : User.UserList}
            edit={
              // Si es un array significa que el administrador nos dio falso
              Array.isArray(isAdministrator) ? '' : User.UserEdit
            }
            create={Array.isArray(isAdministrator) ? '' : User.UserCreate}
            icon={UserIcon}
          />
        ),
        isAdministrator && (
          <Resource
            name="seniorities"
            options={{ label: 'Seniorities' }}
            list={Array.isArray(isAdministrator) ? '' : User.SeniorityList}
            edit={User.SeniorityEdit}
            create={User.SeniorityCreate}
            icon={SeniorityIcon}
          />
        ),
        isAdministrator && (
          <Resource
            name="roles"
            options={{ label: 'Roles' }}
            list={Array.isArray(isAdministrator) ? '' : User.RoleList}
            edit={User.RoleEdit}
            create={User.RoleCreate}
            icon={RoleIcon}
          />
        ),
        isAdministrator && (
          <Resource
            name="areas"
            options={{ label: 'Areas' }}
            list={Array.isArray(isAdministrator) ? '' : User.AreaList}
            edit={User.AreaEdit}
            create={User.AreaCreate}
            icon={ClassIcon}
          />
        ),
        isAdministrator && (
          <Resource
            name="currencies"
            options={{ label: 'Currencies' }}
            list={Array.isArray(isAdministrator) ? '' : User.CurrencyList}
            edit={User.CurrencyEdit}
            create={User.CurrencyCreate}
            icon={CurrencyIcon}
          />
        ),
        <Resource
          name="user-hours-register"
          options={{ label: 'User Hours Register' }}
          list={User.UserHoursRegisterList}
          edit={User.UserHoursRegisterEdit}
          create={User.UserHoursRegisterCreate}
          icon={UserHourRegisterIcon}
        />,
        isAdministrator && (
          <Resource
            name="spent-hours-types"
            options={{ label: 'Spent Hours Types' }}
            list={
              Array.isArray(isAdministrator) ? '' : User.SpentHoursTypesList
            }
            edit={User.SpentHoursTypesEdit}
            create={User.SpentHoursTypesCreate}
            icon={SpentHoursTypesIcon}
          />
        ),
        isAdministrator && (
          <Resource
            name="project-technologies"
            options={{ label: 'Project Technologies' }}
            list={User.ProjectTechnologiesList}
            create={User.ProjectTechnologiesCreate}
            edit={User.ProjectTechnologiesEdit}
            show={User.ProjectTechnologiesShow}
            icon={MemoryIcon}
          />
        ),
        isAdministrator && (
          <Resource
            name="technology-categories"
            options={{ label: 'Technology Categories' }}
            list={User.TechnologyCategoriesList}
            create={User.TechnologyCategoriesCreate}
            edit={User.TechnologyCategoriesEdit}
            show={User.TechnologyCategoriesShow}
            icon={MemoryIcon}
          />
        ),
        isAdministrator && (
          <Resource
            name="technologies"
            options={{ label: 'Technologies' }}
            list={User.TechnologiesList}
            create={User.TechnologiesCreate}
            edit={User.TechnologiesEdit}
            show={User.TechnologiesShow}
          />
        ),
        isAdministrator && (
          <Resource
            name="resource-technologies"
            options={{ label: 'Resource Technologies' }}
            list={User.ResourceTechnologiesList}
            create={User.ResourceTechnologiesCreate}
            edit={User.ResourceTechnologiesEdit}
            show={User.ResourceTechnologiesShow}
          />
        )
      ];
    }}
  </Admin>
);

export default App;
