import { Input } from '@material-ui/core';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import React from 'react';
import { GET_LIST, showNotification } from 'react-admin';

import { DataProvider } from '../providers';
import { AmountTableCell } from './fields';

export const getExistingInvoiceDetailsProjections = invoiceProjection => {
  return DataProvider(GET_LIST, 'invoice-detail-projections', {
    filter: {
      paginate: false,
      invoice: invoiceProjection
    }
  });
};

export const getInvoiceResources = async (
  selectedProject,
  invoiceProjection
) => {
  try {
    const resourcesOnProject = await DataProvider(GET_LIST, 'resources', {
      filter: {
        paginate: false,
        project: selectedProject
      }
    });
    if (invoiceProjection) {
      let invoiceResources = resourcesOnProject.data;
      const invoiceDetailsProjections = await getExistingInvoiceDetailsProjections(
        invoiceProjection
      );
      const invoiceDetails = invoiceDetailsProjections.data;
      invoiceResources = invoiceResources.map(res => {
        const detail =
          invoiceDetails.find(inv => inv.resource === res.id) || {};
        return {
          ...res,
          hours: detail.hours
        };
      });
      return { invoiceResources, invoiceDetails };
    }
    return { invoiceResources: resourcesOnProject.data, invoiceDetails: [] };
  } catch (error) {
    showNotification(
      'Error: There was an error retriving the resources on project information',
      'warning'
    );
    return { invoiceResources: [], invoiceDetails: [] };
  }
};

export const getInvoiceResourcesAndUpdateDetails = async (
  context,
  project,
  updateDetails,
  invoiceProjection
) => {
  const { invoiceResources, invoiceDetails } = await getInvoiceResources(
    project,
    invoiceProjection
  );
  context.setState({ invoiceResources });
  updateDetails({ resources: invoiceResources, details: invoiceDetails });
};

const handleChange = (loadDetail, resource) => {
  return e => {
    const { value } = e.target;
    const invoiceDetail = {
      resource: resource.id,
      hours: value,
      rate: resource.rate
    };
    loadDetail({ invoiceDetail });
  };
};

export const buildResourcesRows = (resources = [], loadDetail, hours) => {
  const rows = resources.map((resource, index) => {
    const hour = 'hour'.concat('[', index, ']');
    return (
      <TableRow>
        <TableCell>{resource.userName}</TableCell>{' '}
        <TableCell>{resource.seniorityName}</TableCell>{' '}
        <TableCell>{resource.roleName}</TableCell>{' '}
        <AmountTableCell source="rate" record={resource} />
        <TableCell>
          {' '}
          <Input
            name={hour}
            type="number"
            defaultValue={resource.hours || hours}
            id={resource.id}
            onChange={handleChange(loadDetail, resource)}
          />
        </TableCell>
      </TableRow>
    );
  });

  return rows;
};
