import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import PropTypes, { any } from 'prop-types';
import React from 'react';
import { withTranslate } from 'react-admin';

import AddNewResource from '../projects/add-new-resource/AddNewResource';

const styles = () => ({
  emptyRow: {
    marginTop: 10,
    color: 'gray'
  }
});

const EmptyRow = ({
  classes,
  projectId,
  addResourcesButton,
  text,
  translate
}) => (
  <>
    <Typography className={classes.emptyRow} variant="body2" component="div">
      {translate(text)}
    </Typography>
    {addResourcesButton && <AddNewResource record={{ id: projectId }} />}
  </>
);

EmptyRow.defaultProps = {
  classes: { emptyRow: {} },
  addResourcesButton: false
};

EmptyRow.propTypes = {
  classes: PropTypes.objectOf(any),
  projectId: PropTypes.string.isRequired,
  addResourcesButton: PropTypes.bool,
  text: PropTypes.string.isRequired,
  translate: PropTypes.func.isRequired
};

export default withStyles(styles)(withTranslate(EmptyRow));
