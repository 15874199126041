import PersonAddIcon from '@material-ui/icons/PersonAdd';
import PropTypes, { any } from 'prop-types';
import React from 'react';
import { Button, Link, withTranslate } from 'react-admin';

const AddNewResource = ({ record, translate }) => (
  <Button
    component={Link}
    to={{
      pathname: '/resources/create',
      search: `?project=${record.id}`
    }}
    label={translate('resources.projects.labels.addResource')}
  >
    <PersonAddIcon />
  </Button>
);

AddNewResource.defaultProps = {
  record: {}
};

AddNewResource.propTypes = {
  record: PropTypes.objectOf(any),
  translate: PropTypes.func.isRequired
};

export default withTranslate(AddNewResource);
