import React from 'react';
import {
  Create,
  Datagrid,
  Edit,
  EditButton,
  Filter,
  List,
  SearchInput,
  SimpleForm,
  TextField,
  TextInput
} from 'react-admin';

import EditingTitle from '../utils/editingTitle';

const SeniorityFilter = props => (
  <Filter {...props}>
    <SearchInput source="q" alwaysOn />
  </Filter>
);

const validateSeniorityCreation = values => {
  const errors = {};
  if (!values.name) {
    errors.name = ['The name is required'];
  }
  return errors;
};

export const SeniorityList = props => (
  <List filters={<SeniorityFilter />} {...props}>
    <Datagrid rowClick="edit">
      <TextField source="name" />
      <EditButton />
    </Datagrid>
  </List>
);

export const SeniorityEdit = props => (
  <Edit title={<EditingTitle.ForName {...props} />} {...props}>
    <SimpleForm>
      <TextInput source="name" />
    </SimpleForm>
  </Edit>
);

export const SeniorityCreate = props => (
  <Create {...props}>
    <SimpleForm validate={validateSeniorityCreation} redirect="/seniorities">
      <TextInput source="name" />
    </SimpleForm>
  </Create>
);
