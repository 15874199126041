import PropTypes from 'prop-types';
import React from 'react';
import {
  Button,
  Create,
  Datagrid,
  Edit,
  EditButton,
  List,
  Show,
  SimpleForm,
  TextField,
  TextInput
} from 'react-admin';

const TechnologyCategoriesTitle = ({ record }) => <span>{record.name}</span>;

export const TechnologyCategoriesList = props => (
  <List {...props}>
    <Datagrid rowClick="show">
      <TextField source="name" />
      <EditButton />
    </Datagrid>
  </List>
);

export const TechnologyCategoriesCreate = props => {
  return (
    <Create {...props}>
      <SimpleForm redirect="show">
        <TextInput source="name" />
        <Button />
      </SimpleForm>
    </Create>
  );
};

export const TechnologyCategoriesEdit = props => {
  return (
    <Edit {...props}>
      <SimpleForm>
        <TextInput source="name" />
      </SimpleForm>
    </Edit>
  );
};

export const TechnologyCategoriesShow = props => {
  return (
    <Show {...props} title={<TechnologyCategoriesTitle {...props} />}>
      <SimpleForm>
        <TextField source="name" />
      </SimpleForm>
    </Show>
  );
};

TechnologyCategoriesTitle.propTypes = {
  record: PropTypes.instanceOf(Object).isRequired
};
