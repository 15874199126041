export default {
  palette: {
    primary: {
      light: '#00c97c',
      main: '#00c79a',
      dark: '#333333',
      contrastText: '#fff'
    },
    secondary: {
      light: '#00c79a',
      main: '#00c97c',
      dark: '#333333',
      contrastText: '#fff'
    }
  },
  typography: {
    title: {
      fontWeight: 400
    }
  }
};
