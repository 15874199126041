/* eslint-disable no-shadow */
import React from 'react';
import { translate } from 'react-admin';

import conversions from './conversions';

const ForResourceName = translate(({ translate, resource }) => {
  return (
    <span>
      {translate('ra.action.edit')} {translate(`resources.${resource}.name`, 1)}
    </span>
  );
});

const ForName = translate(({ translate, record }) => {
  return (
    <span>
      {translate('ra.action.edit')} {`"${record.name}"`}
    </span>
  );
});

const ForEmail = translate(({ translate, record }) => {
  return (
    <span>
      {translate('ra.action.edit')} {`"${record.email}"`}
    </span>
  );
});

const ForIdentifier = translate(({ translate, record }) => {
  return (
    <span>
      {translate('ra.action.edit')} {`"${record.identifier}"`}
    </span>
  );
});

const ForMonthAndYear = translate(({ translate, record }) => {
  return (
    <span>
      {translate('ra.action.edit')} {`"${record.month + 1} - ${record.year}"`}
    </span>
  );
});

const ForResource = translate(({ translate, projectAndUser }) => {
  return (
    <span>
      {translate('ra.action.edit')}{' '}
      {`"${projectAndUser.project.name} ${projectAndUser.user.name}"`}
    </span>
  );
});

const ForHoursRegister = translate(({ translate, record }) => {
  return (
    <span>
      {translate('ra.action.edit')}{' '}
      {`"${conversions.formatDate(record.date)} - ${record.userName} - ${
        record.typeName
      }"`}
    </span>
  );
});

export default {
  ForResourceName,
  ForName,
  ForMonthAndYear,
  ForEmail,
  ForResource,
  ForIdentifier,
  ForHoursRegister
};
