/* eslint react/prop-types: 0 */
import {
  Card,
  CardActions,
  CardHeader,
  Typography,
  withStyles
} from '@material-ui/core';
import { ResponsiveLine } from '@nivo/line';
import { ResponsivePie } from '@nivo/pie';
import { addMonths, addYears } from 'date-fns';
import moment from 'moment';
import React, { Component } from 'react';
import { GET_LIST, showNotification, translate } from 'react-admin';
import { Chart } from 'react-google-charts';
import compose from 'recompose/compose';

import { DataProvider } from './providers';
import conversions from './utils/conversions';

const MIN_DATE = '2015-01-01';
const MAX_DATE = '2040-01-01';
const MONTHS_INTERVAL_BACK = -2;
const MONTHS_INTERVAL_FORWARD = 2;
const COLORS = {
  proyectedSales: '#D7E8B0',
  sales: '#9BC53D',
  proyectedExpenses: '#D37573',
  expenses: '#C3423F'
};

// Defaults formats and reducer for chart values
const formatFns = [
  conversions.format,
  conversions.thousands,
  conversions.show2Decimals
];
const applyFns = (acc, fn) => fn(acc);

const styles = {
  media: {
    height: '100px'
  },
  noData: {
    marginBottom: '15px',
    textAlign: 'center'
  },
  bar: {
    width: '97%',
    marginLeft: '1%'
  },
  line: {
    height: '300px',
    width: '98%'
  },
  pie: {
    height: '500px',
    width: '98%'
  }
};

/* eslint-disable no-param-reassign */
class Dashboard extends Component {
  constructor(props) {
    super(props);

    // 2 months before current date
    const fromDateDefault = addMonths(new Date(), -2);
    // a year after from fromDateDefault
    const toDateDefault = addYears(fromDateDefault, 1);

    this.state = {
      dataForBar: [],
      dataForLine: [],
      dataForPie: [],
      toDate: conversions.formatDate(toDateDefault),
      fromDate: conversions.formatDate(fromDateDefault)
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  updateFromSelection = event => {
    if (event.target.value) {
      this.setState({ fromDate: event.target.value }, this.fetchData);
    }
  };

  updateToSelection = event => {
    if (event.target.value) {
      this.setState({ toDate: event.target.value }, this.fetchData);
    }
  };

  updateDateRange = months => {
    const { toDate, fromDate } = this.state;
    const convertedTo = conversions.parseFromYYYYMMDD(toDate);
    const convertedFrom = conversions.parseFromYYYYMMDD(fromDate);
    const newFrom = addMonths(convertedFrom, months);
    const newTo = addMonths(convertedTo, months);
    this.setState(
      {
        fromDate: conversions.formatDate(newFrom),
        toDate: conversions.formatDate(newTo)
      },
      this.fetchData
    );
  };

  getTimePerAssociate = (from, to) =>
    DataProvider(GET_LIST, 'dashboard', {
      filter: {
        type: 'timePerAssociate',
        from: `${conversions.formatDateYYYYMM(from)}`,
        to: `${conversions.formatDateYYYYMM(to)}`
      }
    })
      .then(response => {
        response.forEach(singleResult => {
          singleResult[4] = new Date(singleResult[4]);
          singleResult[5] = new Date(singleResult[5]);
        });
        setTimeout(() => {
          this.setState({
            dataForBar: []
          });
          this.setState({
            dataForBar: response
          });
        }, 1000);
      })
      .catch(() => {
        showNotification('Error: Could not bring backend data', 'warning');
      });

  getBalance = (from, to) => {
    const { translate } = this.props; // eslint-disable-line no-shadow
    return DataProvider(GET_LIST, 'dashboard', {
      filter: {
        type: 'balance',
        from: `${conversions.formatDateYYYYMM(from)}`,
        to: `${conversions.formatDateYYYYMM(to)}`
      }
    })
      .then(response => {
        response.forEach(element => {
          element.id = translate(
            `pos.dashboard.welcome.lineChart.${element.id}`
          );
          element.data.map(oneElement => {
            if (oneElement.y) {
              oneElement.y = formatFns.reduce(applyFns, oneElement.y);
            }
            return oneElement;
          });
        });

        // XXX Backend returns empty response.data in all objects if no data or problem on query
        const realDataLength = response.reduce(
          (acc, res) => acc + res.data.length,
          0
        );
        this.setState({
          dataForLine: realDataLength ? response : []
        });
      })
      .catch(() => {
        showNotification('Error: Could not bring backend data', 'warning');
      });
  };

  getIncomeByProject = (from, to) =>
    DataProvider(GET_LIST, 'dashboard', {
      filter: {
        type: 'incomesByProject',
        from: `${conversions.formatDateYYYYMM(from)}`,
        to: `${conversions.formatDateYYYYMM(to)}`
      }
    })
      .then(response => {
        response.data.forEach(projectIncome => {
          if (projectIncome.value) {
            projectIncome.value = formatFns.reduce(
              applyFns,
              projectIncome.value
            );
          }
        });
        this.setState({
          dataForPie: response
        });
      })
      .catch(() => {
        showNotification('Error: Could not bring backend data', 'warning');
      });

  fetchData() {
    const { fromDate, toDate } = this.state;
    const args = [fromDate, toDate];
    const fetchFns = [
      this.getIncomeByProject,
      this.getBalance,
      this.getTimePerAssociate
    ];
    fetchFns.forEach(fn => fn.apply(this, args));
  }

  render() {
    const {
      dataForBar,
      dataForLine,
      dataForPie,
      fromDate,
      toDate
    } = this.state;
    const { translate } = this.props; // eslint-disable-line no-shadow
    return (
      <>
        <Card>
          <CardHeader
            title={translate('pos.dashboard.welcome.title')}
            subheader={translate('pos.dashboard.welcome.subheader')}
          />
          <CardActions>
            <p>
              {translate('pos.dashboard.welcome.actions')}
              <br />
              <button
                type="button"
                name="back"
                onClick={this.updateDateRange.bind(null, MONTHS_INTERVAL_BACK)}
                className="dateSearchButton"
              >
                &lt;
              </button>
              <input
                type="date"
                name="from"
                pattern="\d{4}-\d{2}-\d{2}"
                value={fromDate}
                onChange={this.updateFromSelection}
                onKeyDown={e => e.preventDefault()}
                min={MIN_DATE}
                max={MAX_DATE}
                className="dateInput"
                required
              />
              <input
                type="date"
                name="to"
                pattern="\d{4}-\d{2}-\d{2}"
                value={toDate}
                onChange={this.updateToSelection}
                onKeyDown={e => e.preventDefault()}
                min={MIN_DATE}
                max={MAX_DATE}
                required
              />
              <button
                type="button"
                name="forward"
                onClick={this.updateDateRange.bind(
                  null,
                  MONTHS_INTERVAL_FORWARD
                )}
                className="dateSearchButton"
              >
                &gt;
              </button>
            </p>
          </CardActions>
        </Card>
        <Card>
          <CardHeader
            title={translate('pos.dashboard.welcome.lineChart.salesExpenses')}
          />
          {dataForLine.length ? (
            <div style={styles.line}>
              <MyResponsiveLine
                data={conversions.dataToTimeline(dataForLine)}
                month={translate('pos.dashboard.welcome.month')}
                billing={translate('pos.dashboard.welcome.lineChart.billing')}
              />
            </div>
          ) : (
            <Typography style={styles.noData} component="p">
              {translate('pos.dashboard.noData')}
            </Typography>
          )}
        </Card>
        <Card>
          <CardHeader
            title={translate('pos.dashboard.welcome.barChart.status')}
          />
          {dataForBar.length ? (
            <div style={styles.bar}>
              <MyResponsiveBar
                data={dataForBar}
                month={translate('pos.dashboard.welcome.month')}
                associated={translate(
                  'pos.dashboard.welcome.barChart.associated'
                )}
              />
            </div>
          ) : (
            <Typography style={styles.noData} component="p">
              {translate('pos.dashboard.noData')}
            </Typography>
          )}
        </Card>
        <Card>
          <CardHeader
            title={translate('pos.dashboard.welcome.pieChart.revenueCustomer')}
            subheader={translate('pos.dashboard.welcome.pieChart.subheader')}
          />
          {dataForPie.length ? (
            <div style={styles.pie}>
              <MyResponsivePie data={dataForPie} />
            </div>
          ) : (
            <Typography style={styles.noData} component="p">
              {translate('pos.dashboard.noData')}
            </Typography>
          )}
        </Card>
      </>
    );
  }
}

// Dont show google charts error
const chartEvents = [
  {
    eventName: 'error',
    callback(wrapper) {
      wrapper.google.visualization.errors.removeError();
      wrapper.eventArgs[0].container.innerHTML = '';
    }
  }
];

const MyResponsiveBar = ({ data }) => (
  <Chart
    width="100%"
    height={`${data.length * 41 + 50}px`}
    chartType="Timeline"
    loader={<div>Loading Chart</div>}
    chartEvents={chartEvents}
    data={[
      [
        { type: 'string', id: 'Associate' },
        { type: 'string', id: 'Project' },
        { type: 'string', role: 'style' },
        { type: 'string', role: 'tooltip' },
        { type: 'date', id: 'Start' },
        { type: 'date', id: 'End' }
      ],
      ...data
    ]}
  />
);

const MyResponsiveLine = ({ data, month, billing }) => (
  <ResponsiveLine
    data={data}
    enableArea
    areaBlendMode="normal"
    areaOpacity={0.5}
    margin={{ top: 10, right: 150, bottom: 80, left: 65 }}
    curve="monotoneX"
    xFormat={timeString => moment(timeString).format('MMM')}
    axisTop={null}
    axisRight={null}
    axisBottom={{
      orient: 'bottom',
      tickSize: 5,
      tickPadding: 5,
      tickRotation: 0,
      legend: `${month}`,
      legendOffset: 41,
      legendPosition: 'middle',
      format: timeString => moment(timeString).format('MMM')
    }}
    axisLeft={{
      orient: 'left',
      tickSize: 5,
      tickPadding: 5,
      tickRotation: 0,
      legend: `${billing}`,
      legendOffset: -55,
      legendPosition: 'middle'
    }}
    enableGridX
    colors={[
      COLORS.proyectedExpenses,
      COLORS.proyectedSales,
      COLORS.expenses,
      COLORS.sales
    ].reverse()}
    pointSize={8}
    useMesh
    enableSlices="x"
    legends={[
      {
        anchor: 'bottom-right',
        direction: 'column',
        justify: false,
        translateX: 85,
        translateY: -10,
        itemsSpacing: 0,
        itemDirection: 'left-to-right',
        itemWidth: 66,
        itemHeight: 25,
        itemOpacity: 0.75,
        symbolSize: 13,
        symbolShape: 'circle',
        symbolBorderColor: 'rgba(0, 0, 0, .5)',
        effects: [
          {
            on: 'hover',
            style: {
              itemOpacity: 1
            }
          }
        ]
      }
    ]}
  />
);

const MyResponsivePie = ({ data }) => (
  <ResponsivePie
    data={data}
    margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
    innerRadius={0.05}
    padAngle={1}
    cornerRadius={3}
    colors={{ scheme: 'nivo' }}
    borderWidth={1}
    borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
    radialLabelsSkipAngle={10}
    radialLabelsTextXOffset={6}
    radialLabelsTextColor="#333333"
    radialLabelsLinkOffset={0}
    radialLabelsLinkDiagonalLength={16}
    radialLabelsLinkHorizontalLength={24}
    radialLabelsLinkStrokeWidth={1}
    radialLabelsLinkColor={{ from: 'color' }}
    slicesLabelsSkipAngle={10}
    slicesLabelsTextColor="#333333"
    animate
    motionStiffness={90}
    motionDamping={15}
    legends={[
      {
        anchor: 'bottom',
        direction: 'row',
        translateY: 56,
        itemWidth: 100,
        itemHeight: 18,
        itemTextColor: '#999',
        symbolSize: 18,
        symbolShape: 'circle',
        effects: [
          {
            on: 'hover',
            style: {
              itemTextColor: '#000'
            }
          }
        ]
      }
    ]}
  />
);

const enhance = compose(translate, withStyles(styles));

export default enhance(Dashboard);
