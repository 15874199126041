import PropTypes from 'prop-types';
import React from 'react';
import {
  ChipField,
  Create,
  Datagrid,
  Edit,
  EditButton,
  Filter,
  List,
  ReferenceArrayField,
  ReferenceArrayInput,
  ReferenceField,
  ReferenceInput,
  SelectArrayInput,
  SelectInput,
  Show,
  SimpleForm,
  SingleFieldList,
  TextField,
  TextInput
} from 'react-admin';

const TechnologiesTitle = ({ record }) => <span>{record.name}</span>;

const TechnologiesFilter = props => (
  <Filter {...props}>
    <ReferenceInput
      source="category"
      reference="technology-categories"
      allowempty
      alwaysOn
    >
      <SelectInput optionText="name" />
    </ReferenceInput>
    <ReferenceInput
      source="related"
      reference="technologies"
      allowempty
      alwaysOn
    >
      <SelectInput optionText="name" />
    </ReferenceInput>
  </Filter>
);

export const TechnologiesList = props => {
  return (
    <List {...props} filters={<TechnologiesFilter />}>
      <Datagrid rowClick="show">
        <TextField source="name" label="nombre" />
        <ReferenceField
          source="category"
          reference="technology-categories"
          linkType={false}
        >
          <ChipField source="name" color="primary" />
        </ReferenceField>
        <ReferenceArrayField reference="technologies" source="related">
          <SingleFieldList linkType={false}>
            <ChipField source="name" color="secondary" />
          </SingleFieldList>
        </ReferenceArrayField>
        <EditButton />
      </Datagrid>
    </List>
  );
};

export const TechnologiesCreate = props => {
  return (
    <Create {...props}>
      <SimpleForm redirect="edit">
        <TextInput source="name" />
        <ReferenceInput source="category" reference="technology-categories">
          <SelectInput optionText="name" />
        </ReferenceInput>
      </SimpleForm>
    </Create>
  );
};

export const TechnologiesShow = props => {
  return (
    <Show {...props} title={<TechnologiesTitle />}>
      <SimpleForm {...props} redirect="list">
        <TextField source="name" />
        <ReferenceField
          source="category"
          reference="technology-categories"
          linkType={false}
        >
          <ChipField source="name" color="primary" />
        </ReferenceField>
        <ReferenceArrayField reference="technologies" source="related">
          <SingleFieldList linkType={false}>
            <ChipField source="name" color="secondary" />
          </SingleFieldList>
        </ReferenceArrayField>
      </SimpleForm>
    </Show>
  );
};

export const TechnologiesEdit = props => {
  return (
    <Edit {...props} title={<TechnologiesTitle />}>
      <SimpleForm {...props}>
        <TextInput source="name" />
        <ReferenceInput source="category" reference="technology-categories">
          <SelectInput optionText="name" />
        </ReferenceInput>
        <ReferenceArrayInput source="related" reference="technologies">
          <SelectArrayInput optionText="name" />
        </ReferenceArrayInput>
      </SimpleForm>
    </Edit>
  );
};

TechnologiesTitle.propTypes = {
  record: PropTypes.instanceOf(Object).isRequired
};
