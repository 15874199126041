/* eslint-disable react/prop-types */
import React from 'react';
import {
  Button,
  Create,
  Datagrid,
  DateField,
  DateInput,
  Edit,
  EditButton,
  Filter,
  List,
  ReferenceField,
  ReferenceInput,
  ReferenceManyField,
  SearchInput,
  SelectInput,
  Show,
  SimpleForm,
  SimpleShowLayout,
  TextField,
  TextInput,
  withTranslate
} from 'react-admin';

import EmptyDataGuard from '../components/EmptyDataguard/EmptyDataGuard';
import { AmountField } from '../components/fields';
import EmptyRow from '../components/invoice-empty-row/EmptyRow';
import ProjetShowActions from '../components/projects/ShowToolbar';
import conversions from '../utils/conversions';
import EditingTitle from '../utils/editingTitle';

const ProjectFilter = props => (
  <Filter {...props}>
    <SearchInput source="q" alwaysOn />
    <ReferenceInput source="client" reference="clients" allowempty alwaysOn>
      <SelectInput optionText="name" />
    </ReferenceInput>
    <ReferenceInput
      source="currency"
      reference="currencies"
      allowempty
      alwaysOn
    >
      <SelectInput optionText="name" />
    </ReferenceInput>
    <ReferenceInput
      source="projectType"
      reference="projectTypes"
      allowempty
      alwaysOn
    >
      <SelectInput optionText="name" />
    </ReferenceInput>
  </Filter>
);

export const ProjectList = props => (
  <List filters={<ProjectFilter />} {...props}>
    <Datagrid rowClick="show">
      <TextField source="name" />
      <ReferenceField source="client" reference="clients" linkType={false}>
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField source="currency" reference="currencies" linkType={false}>
        <TextField source="name" />
      </ReferenceField>
      <DateField source="startingDate" options={{ timeZone: 'UTC' }} />
      <DateField source="endingDate" options={{ timeZone: 'UTC' }} />
      <DateField source="endingVarianceDate" options={{ timeZone: 'UTC' }} />
      <ReferenceField
        source="projectType"
        reference="projectTypes"
        linkType={false}
      >
        <TextField source="name" />
      </ReferenceField>
      <EditButton />
    </Datagrid>
  </List>
);

export const ProjectEdit = props => (
  <Edit title={<EditingTitle.ForName {...props} />} {...props}>
    <SimpleForm redirect="/projects">
      <TextInput source="name" />
      <ReferenceInput source="client" reference="clients">
        <SelectInput optionText="name" />
      </ReferenceInput>
      <ReferenceInput source="projectType" reference="projectTypes">
        <SelectInput optionText="name" />
      </ReferenceInput>
      <ReferenceInput source="currency" reference="currencies">
        <SelectInput optionText="name" />
      </ReferenceInput>
      <DateInput
        source="startingDate"
        options={{ timeZone: 'UTC' }}
        format={conversions.formatDate}
      />
      <DateInput
        source="endingDate"
        options={{ timeZone: 'UTC' }}
        format={conversions.formatDate}
      />
      <DateInput
        source="endingVarianceDate"
        options={{ timeZone: 'UTC' }}
        format={conversions.formatDate}
      />
      <Button />
      <Datagrid source="resources" />
    </SimpleForm>
  </Edit>
);

export const ProjectCreate = props => (
  <Create {...props}>
    <SimpleForm redirect="show">
      <TextInput source="name" />
      <ReferenceInput source="client" reference="clients">
        <SelectInput optionText="name" />
      </ReferenceInput>
      <ReferenceInput source="projectType" reference="projectTypes">
        <SelectInput optionText="name" />
      </ReferenceInput>
      <ReferenceInput source="currency" reference="currencies">
        <SelectInput optionText="name" />
      </ReferenceInput>
      <DateInput
        source="startingDate"
        options={{ timeZone: 'UTC' }}
        format={conversions.formatDate}
      />
      <DateInput
        source="endingDate"
        options={{ timeZone: 'UTC' }}
        format={conversions.formatDate}
      />
      <DateInput
        source="endingVarianceDate"
        options={{ timeZone: 'UTC' }}
        format={conversions.formatDate}
      />
      <Button />
      <Datagrid source="resources" />
    </SimpleForm>
  </Create>
);

export const ProjectShow = withTranslate(props => (
  <Show
    title={<EditingTitle.ForName {...props} />}
    {...props}
    actions={<ProjetShowActions />}
  >
    <SimpleShowLayout>
      <TextField source="name" />
      <ReferenceField source="client" reference="clients">
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField source="projectType" reference="projectTypes">
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField source="currency" reference="currencies">
        <TextField source="name" />
      </ReferenceField>
      <DateField
        source="startingDate"
        options={{ timeZone: 'UTC' }}
        format={conversions.formatDate}
      />
      <DateField
        source="endingDate"
        options={{ timeZone: 'UTC' }}
        format={conversions.formatDate}
      />
      <DateField
        source="endingVarianceDate"
        options={{ timeZone: 'UTC' }}
        format={conversions.formatDate}
      />
      <ReferenceManyField
        label={props.translate('resources.projects.labels.resources')}
        reference="resources"
        target="project"
      >
        <EmptyDataGuard
          empty={<EmptyRow text="components.emptyRow.noResources" />}
        >
          <Datagrid rowClick="edit">
            <ReferenceField source="role" reference="roles">
              <TextField source="name" />
            </ReferenceField>
            <ReferenceField source="seniority" reference="seniorities">
              <TextField source="name" />
            </ReferenceField>
            <ReferenceField source="user" reference="users">
              <TextField source="name" />
            </ReferenceField>
            <AmountField source="rate" />
            <DateField
              source="startingDate"
              options={{ timeZone: 'UTC' }}
              format={conversions.formatDate}
            />
            <DateField
              source="endingDate"
              options={{ timeZone: 'UTC' }}
              format={conversions.formatDate}
            />
          </Datagrid>
        </EmptyDataGuard>
      </ReferenceManyField>
    </SimpleShowLayout>
  </Show>
));
