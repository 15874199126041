import React from 'react';
import {
  Create,
  Datagrid,
  DateField,
  DateInput,
  Edit,
  EditButton,
  List,
  NumberInput,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextField
} from 'react-admin';

import { AmountField } from '../components/fields';
import conversions from '../utils/conversions';
import EditingTitle from '../utils/editingTitle';

const validateExchangeRateCreation = values => {
  const errors = {};
  if (!values.currency) {
    errors.currency = ['The currency is required'];
  }
  if (!values.value) {
    errors.value = ['The value is required'];
  }
  if (!values.date) {
    errors.date = ['The date is required'];
  }
  return errors;
};

export const ExchangeRateList = props => (
  <List {...props}>
    <Datagrid rowClick="edit">
      <ReferenceField source="currency" reference="currencies" linkType={false}>
        <TextField source="name" />
      </ReferenceField>
      <AmountField source="value" />
      <DateField source="date" options={{ timeZone: 'UTC' }} />
      <EditButton />
    </Datagrid>
  </List>
);

export const ExchangeRateEdit = props => (
  <Edit title={<EditingTitle.ForResourceName {...props} />} {...props}>
    <SimpleForm>
      <ReferenceInput source="currency" reference="currencies">
        <SelectInput optionText="name" />
      </ReferenceInput>
      <NumberInput
        source="value"
        format={conversions.format}
        parse={conversions.parse}
      />
      <DateInput
        source="date"
        options={{ timeZone: 'UTC' }}
        format={conversions.formatDate}
      />
    </SimpleForm>
  </Edit>
);

export const ExchangeRateCreate = props => (
  <Create {...props}>
    <SimpleForm
      validate={validateExchangeRateCreation}
      redirect="/exchange-rates"
    >
      <ReferenceInput source="currency" reference="currencies">
        <SelectInput optionText="name" />
      </ReferenceInput>
      <NumberInput
        source="value"
        format={conversions.format}
        parse={conversions.parse}
        defaultValue={0}
      />
      <DateInput
        source="date"
        defaultValue={new Date(new Date().setUTCHours(0, 0, 0, 0))}
        options={{ timeZone: 'UTC' }}
        format={conversions.formatDate}
      />
    </SimpleForm>
  </Create>
);
