import 'react-datepicker/dist/react-datepicker.css';

import { withStyles } from '@material-ui/core/styles';
import { es } from 'date-fns/locale';
import PropTypes from 'prop-types';
import React from 'react';
import { withTranslate } from 'react-admin';
import DatePicker from 'react-datepicker';
import { connect } from 'react-redux';
import compose from 'recompose/compose';

import { loadInvoiceDate } from '../store/actions/invoiceAction';

const styles = {
  container: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  textField: {
    width: 256
  },
  periodContainer: {
    marginTop: '1em'
  },
  periodLabel: {
    marginBottom: '5px'
  }
};

function DatePickerCustom(props) {
  const { translate, currentDate } = props;
  const updateInvoiceDate = event => {
    props.loadDate({ invoiceDate: event });
  };
  return (
    <div style={styles.periodContainer}>
      <span style={styles.periodLabel}>
        {translate(`resources.invoices.fields.period`)}
      </span>
      <DatePicker
        dateFormat="MMMM yyyy"
        locale={es}
        inline
        showMonthYearPicker
        showFullMonthYearPicker
        showFourColumnMonthYearPicker
        fixedHeight
        closeOnScroll={false}
        source="period"
        selected={currentDate}
        onChange={updateInvoiceDate}
        placeholderText={translate(`resources.invoices.fields.period`)}
      />
    </div>
  );
}

const mapDispatchToProps = dispatch => {
  return {
    loadDate: payload => {
      dispatch(loadInvoiceDate(payload));
    }
  };
};

const mapStateToProps = state => {
  return {
    allResources: state.admin.resources,
    currentDate: state.invoice.invoiceDate
  };
};

DatePickerCustom.propTypes = {
  loadDate: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired,
  currentDate: PropTypes.instanceOf(Date).isRequired
};

const enhance = compose(connect(mapStateToProps, mapDispatchToProps));

const DatePickerComponent = enhance(
  withStyles(styles)(withTranslate(DatePickerCustom))
);

export default DatePickerComponent;
