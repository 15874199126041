/* eslint-disable no-shadow */
/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import {
  DeleteButton,
  SaveButton,
  showNotification,
  Toolbar,
  UPDATE
} from 'react-admin';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';

import { DataProvider } from '../../providers';

const updatedRecord = values => {
  const newPeriod = new Date();
  newPeriod.setFullYear(values.year);
  newPeriod.setMonth(values.month);
  newPeriod.setDate('01');
  return {
    id: values.id,
    amount: values.amount,
    period: newPeriod
  };
};

class EditMonthlyExpenseButtonView extends Component {
  handleClick = () => {
    const { push, record, formValues, showNotification } = this.props;

    DataProvider(UPDATE, 'monthly-expenses', {
      id: record.id,
      data: updatedRecord(formValues)
    })
      .then(() => {
        showNotification('Monthly Expense Uptaded');
        push('/monthly-expenses');
      })
      .catch(() => {
        showNotification('Error: Monthly Expense not Uptaded', 'warning');
      });
  };

  render() {
    const {
      record,
      showNotification,
      handleSubmitWithRedirect,
      ...props
    } = this.props;

    return (
      <SaveButton handleSubmitWithRedirect={this.handleClick} {...props} />
    );
  }
}

/*
  mapFormValuesToProp se tiene que poder resolver mejor.
  Docu de ReduxForm:
  getFormValues(formName:String) returns (state) => formValues:Object
*/
const mapFormValuesToProp = state => {
  return {
    formValues: state.form['record-form'].values
  };
};

const EditMonthlyExpenseButton = connect(mapFormValuesToProp, {
  showNotification,
  push
})(EditMonthlyExpenseButtonView);

const EditMonthlyExpensesToolbar = props => (
  <Toolbar {...props}>
    <EditMonthlyExpenseButton {...props} />
    <DeleteButton />
  </Toolbar>
);

export default EditMonthlyExpensesToolbar;
