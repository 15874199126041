/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
/* eslint max-classes-per-file: ["error", 2] */
import { parse } from 'query-string';
import React from 'react';
import {
  AutocompleteInput,
  Create,
  Datagrid,
  DateField,
  DateInput,
  Edit,
  EditButton,
  Filter,
  GET_ONE,
  List,
  NumberInput,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextField,
  withTranslate
} from 'react-admin';
import { connect } from 'react-redux';
import compose from 'recompose/compose';

import { AmountField } from '../components/fields';
import { DataProvider } from '../providers';
import conversions from '../utils/conversions';
import EditingTitle from '../utils/editingTitle';

const validateResourceCreation = values => {
  const errors = {};
  if (!values.project) {
    errors.project = ['The project is required'];
  }
  if (!values.role) {
    errors.role = ['The role is required'];
  }
  if (!values.seniority) {
    errors.seniority = ['The seniority is required'];
  }
  if (!values.user) {
    errors.user = ['The user is required'];
  }
  return errors;
};
const messageProjectRangeValidation = (project, { translate }) =>
  project
    ? translate(`resources.projects.validation.projectRange`, {
        startingDate: conversions.formatDate(project.startingDate),
        endingDate: conversions.formatDate(project.endingDate)
      })
    : undefined;
const messageRangeValidation = (source, { translate }) =>
  translate(`resources.projects.validation.range.${source}`);

const validateDate = (project, messages) => (value, values) => {
  const currentDate = conversions.formatDate(value);
  if (values.startingDate < values.endingDate && project) {
    const startingDate = conversions.formatDate(project.startingDate);
    const endingDate = conversions.formatDate(project.endingDate);
    return startingDate <= currentDate && currentDate <= endingDate
      ? undefined
      : messages[0];
  }
  return values.endingDate <= values.startingDate ? messages[1] : undefined;
};

const ResourceFilter = props => (
  <Filter {...props}>
    <ReferenceInput source="project" reference="projects" allowempty alwaysOn>
      <SelectInput optionText="name" />
    </ReferenceInput>
    <ReferenceInput source="role" reference="roles" allowempty alwaysOn>
      <SelectInput optionText="name" />
    </ReferenceInput>
    <ReferenceInput
      source="seniority"
      reference="seniorities"
      allowempty
      alwaysOn
    >
      <SelectInput optionText="name" />
    </ReferenceInput>
    <ReferenceInput source="user" reference="users" allowempty alwaysOn>
      <SelectInput optionText="name" />
    </ReferenceInput>
  </Filter>
);

export const ResourceList = props => (
  <List filters={<ResourceFilter />} {...props}>
    <Datagrid rowClick="edit">
      <ReferenceField source="project" reference="projects" linkType={false}>
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField source="role" reference="roles" linkType={false}>
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField
        source="seniority"
        reference="seniorities"
        linkType={false}
      >
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField source="user" reference="users" linkType={false}>
        <TextField source="name" />
      </ReferenceField>
      <AmountField source="rate" />
      <DateField source="startingDate" options={{ timeZone: 'UTC' }} />
      <DateField source="endingDate" options={{ timeZone: 'UTC' }} />
      <EditButton />
    </Datagrid>
  </List>
);

// eslint-disable-next-line
class ResEdit extends React.Component {
  render() {
    const { props } = this;
    const { resources, projects, users } = props.allResources;
    const res = { ...resources.data[props.id] };
    const projectAndUser = {
      project: { ...projects.data }[res.project],
      user: { ...users.data }[res.user]
    };

    return (
      <Edit
        title={
          <EditingTitle.ForResource
            projectAndUser={projectAndUser}
            {...props}
          />
        }
        {...props}
      >
        <SimpleForm>
          <ReferenceInput source="project" reference="projects">
            <SelectInput source="name" />
          </ReferenceInput>
          <ReferenceInput source="role" reference="roles">
            <SelectInput source="name" />
          </ReferenceInput>
          <ReferenceInput source="seniority" reference="seniorities">
            <SelectInput source="name" />
          </ReferenceInput>
          <ReferenceInput source="user" reference="users">
            <SelectInput source="name" />
          </ReferenceInput>
          <NumberInput
            source="rate"
            format={conversions.format}
            parse={conversions.parse}
          />
          <DateInput
            source="startingDate"
            options={{ timeZone: 'UTC' }}
            format={conversions.formatDate}
            validate={[
              validateDate(projectAndUser.project, [
                messageProjectRangeValidation(
                  projectAndUser.project,
                  this.props
                ),
                messageRangeValidation('startingDate', this.props)
              ])
            ]}
          />
          <DateInput
            source="endingDate"
            options={{ timeZone: 'UTC' }}
            format={conversions.formatDate}
            validate={[
              validateDate(projectAndUser.project, [
                messageProjectRangeValidation(
                  projectAndUser.project,
                  this.props
                ),
                messageRangeValidation('endingDate', this.props)
              ])
            ]}
          />
        </SimpleForm>
      </Edit>
    );
  }
}

const mapStateToProps = state => ({
  allResources: state.admin.resources
});

const enhance = compose(connect(mapStateToProps, {}));

export const ResourceEdit = enhance(withTranslate(ResEdit));

class ResCreate extends React.Component {
  constructor(props) {
    super(props);
    const { project } = parse(props.location.search);
    this.redirect = project ? `/projects/${project}/show` : '/resources';
    this.state = {
      project: undefined,
      error: false,
      loading: true,
      projectId: project
    };
    if (
      (this.state.projectId && !this.state.project) ||
      this.state.projectId !== project
    ) {
      this.updateProject(this.state.projectId);
    }
  }

  updateProject(project) {
    DataProvider(GET_ONE, 'projects', { id: project })
      .then(({ data }) => {
        this.setState({
          project: data,
          loading: false,
          projectId: project
        });
        return data;
      })
      .catch(() => {
        this.setState({
          error: true,
          loading: false
        });
      });
  }

  render() {
    const { props } = this;
    const handleChange = (event, project) => {
      this.updateProject(project);
    };
    return (
      <Create {...props}>
        <SimpleForm
          validate={validateResourceCreation}
          redirect={this.redirect}
        >
          <ReferenceInput
            source="project"
            reference="projects"
            onChange={handleChange}
          >
            <AutocompleteInput source="name" />
          </ReferenceInput>
          <ReferenceInput source="role" reference="roles">
            <SelectInput source="name" />
          </ReferenceInput>
          <ReferenceInput source="seniority" reference="seniorities">
            <SelectInput source="name" />
          </ReferenceInput>
          <ReferenceInput source="user" reference="users">
            <SelectInput source="name" />
          </ReferenceInput>
          <NumberInput
            source="rate"
            format={conversions.format}
            parse={conversions.parse}
          />
          <DateInput
            {...props}
            source="startingDate"
            defaultValue={
              !this.state.loading && !this.state.error && this.state.project
                ? this.state.project.startingDate
                : new Date(new Date().setUTCHours(0, 0, 0, 0))
            }
            options={{ timeZone: 'UTC' }}
            format={conversions.formatDate}
            validate={[
              validateDate(this.state.project, [
                messageProjectRangeValidation(this.state.project, this.props),
                messageRangeValidation('startingDate', this.props)
              ])
            ]}
          />
          <DateInput
            {...props}
            source="endingDate"
            defaultValue={
              this.state.project && !(this.state.loading || this.state.error)
                ? this.state.project.endingDate
                : new Date(new Date().setUTCHours(0, 0, 0, 0))
            }
            options={{ timeZone: 'UTC' }}
            format={conversions.formatDate}
            validate={[
              validateDate(this.state.project, [
                messageProjectRangeValidation(this.state.project, this.props),
                messageRangeValidation('endingDate', this.props)
              ])
            ]}
          />
        </SimpleForm>
      </Create>
    );
  }
}
export const ResourceCreate = enhance(withTranslate(ResCreate));
