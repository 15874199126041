import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { GET_ONE, showNotification, TextInput } from 'react-admin';

import { DataProvider } from '../../providers';

const getCurrencyName = currencyId => {
  return DataProvider(GET_ONE, 'currencies', {
    id: currencyId
  })
    .then(foundCurrency => {
      return foundCurrency.data.name;
    })
    .catch(() => {
      showNotification(
        'Error: There was an error retriving the currency information',
        'warning'
      );
    });
};

const getProjectCurrency = selectedProject => {
  return DataProvider(GET_ONE, 'projects', {
    id: selectedProject
  })
    .then(foundProject => {
      return getCurrencyName(foundProject.data.currency);
    })
    .catch(() => {
      showNotification(
        'Error: There was an error retriving the project information',
        'warning'
      );
    });
};

class CurrencyField extends Component {
  constructor(props) {
    super(props);
    this.state = { currencyName: '' };
  }

  componentDidMount() {
    const { project } = this.props;
    if (project) {
      getProjectCurrency(project).then(currencyName => {
        this.setState({ currencyName });
      });
    }
  }

  componentDidUpdate(prevProps) {
    const { project } = this.props;
    if (project && project !== prevProps.project) {
      getProjectCurrency(project).then(currencyName => {
        this.setState({ currencyName });
      });
    }
  }

  render() {
    const { currencyName } = this.state;
    return <TextInput disabled source={currencyName} />;
  }
}

CurrencyField.defaultProps = {
  project: null
};

CurrencyField.propTypes = {
  project: PropTypes.string
};

export default CurrencyField;
