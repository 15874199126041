import spanishMessages from '@blackbox-vision/ra-language-spanish';

export default {
  ...spanishMessages,
  pos: {
    search: 'Buscar',
    dashboard: {
      welcome: {
        title: 'Bienvenidx a Cambá Admin App',
        subheader:
          'Aplicación para gestionar proyectos en los que trabajamos y proyectar los ingresos percibidos.',
        actions: 'Busqueda por fechas',
        month: 'Meses',
        barChart: {
          status: 'Estado de ocupación',
          associated: 'Y: Asociados'
        },
        lineChart: {
          salesExpenses: 'Gastos / Ventas',
          billing: 'Facturación (Expresados en miles)',
          Ventas: 'Ventas',
          Gastos: 'Gastos',
          'Ventas Proyectadas': 'Ventas Proyectadas',
          'Gastos Proyectados': 'Gastos Proyectados'
        },
        pieChart: {
          revenueCustomer: 'Facturación: Ingresos por cliente',
          subheader: 'Expresado en miles de Pesos'
        }
      },
      noData: 'No se encontraron datos para el periodo seleccionado'
    },
    login: {
      fields: {
        email: 'Email',
        password: 'Contraseña',
        signIn: 'Ingresar'
      }
    }
  },
  calendarize: {
    months: {
      jan: 'Enero',
      feb: 'Febrero',
      mar: 'Marzo',
      apr: 'Abril',
      may: 'Mayo',
      jun: 'Junio',
      jul: 'Julio',
      aug: 'Agosto',
      sep: 'Septiembre',
      oct: 'Octubre',
      nov: 'Noviembre',
      dec: 'Diciembre'
    }
  },
  components: {
    emptyRow: {
      noResources: 'El proyecto seleccionado no tiene trabajadoras asignadas',
      noInvoiceDetails: 'La factura actual no posee detalles'
    }
  },
  resources: {
    areas: {
      name: 'Área |||| Areas',
      fields: {
        name: 'Nombre',
        includeInOccupancyChart: 'Incluir usuarix en gráfico de ocupación'
      }
    },
    clients: {
      name: 'Cliente |||| Clientes',
      fields: {
        name: 'Nombre'
      }
    },
    resources: {
      name: 'Trabajadora |||| Trabajadoras',
      fields: {
        project: 'Proyecto',
        role: 'Rol',
        seniority: 'Seniority',
        user: 'Usuarix',
        rate: 'Tarifa',
        startingDate: 'Fecha de inicio',
        endingDate: 'Fecha de Fin'
      },
      notification: {
        resource_period_error:
          'El periodo de la trabajadora debe estar dentro del periodo del proyecto.'
      }
    },
    users: {
      name: 'Usuarix |||| Usuarixs',
      fields: {
        name: 'Nombre',
        email: 'Email',
        administrator: 'Administrador'
      }
    },
    seniorities: {
      name: 'Seniority |||| Seniorities',
      fields: {
        name: 'Nombre'
      }
    },
    currencies: {
      name: 'Moneda |||| Monedas',
      fields: {
        name: 'Nombre'
      }
    },
    roles: {
      name: 'Rol |||| Roles',
      fields: {
        name: 'Nombre'
      }
    },
    'exchange-rates': {
      name: 'Tipo de Cambio |||| Tipos de Cambio',
      fields: {
        currency: 'Moneda',
        value: 'Valor',
        date: 'Fecha'
      }
    },
    'monthly-expenses': {
      name: 'Gasto Mensual |||| Gastos Mensuales',
      fields: {
        period: 'Periodo',
        amount: 'Monto',
        month: 'Mes',
        year: 'Año'
      }
    },
    'monthly-expense-projections': {
      name: 'Gasto Mensual Proyectado |||| Gastos Mensuales Proyectados',
      fields: {
        period: 'Periodo',
        amount: 'Monto',
        month: 'Mes',
        year: 'Año'
      }
    },
    projects: {
      name: 'Proyecto |||| Proyectos',
      fields: {
        name: 'Nombre',
        client: 'Cliente',
        currency: 'Tipo de Cambio',
        startingDate: 'Fecha de inicio',
        endingDate: 'Fecha de Fin',
        projectType: 'Tipo de Proyecto',
        endingVarianceDate: 'Fecha de Desvio'
      },
      labels: {
        addResource: 'Agregar trabajadora',
        resources: 'Trabajadora'
      },
      validation: {
        projectRange:
          'El proyecto se desarrolla entre las fechas %{startingDate} y %{endingDate}. La fecha seleccionada debe estar dentro de ese período.',
        range: {
          startingDate:
            'La fecha de inicio debe ser menor que la fecha de finalización.',
          endingDate:
            'La fecha de finalización debe ser mayor que la fecha de inicio.'
        }
      }
    },
    invoices: {
      name: 'Factura |||| Facturas',
      fields: {
        identifier: 'Identificador',
        period: 'Período',
        month: 'Mes',
        year: 'Año',
        currency: 'Moneda',
        amount: 'Monto',
        invoiceDetails: 'Detalle de factura',
        resource: 'Socie',
        role: 'Rol',
        seniority: 'Seniority',
        exchangeRate: 'Valor Hora',
        hours: 'Horas',
        project: 'Proyecto',
        client: 'Cliente'
      },
      outOfPeriod: 'Fuera de periodo',
      validation: {
        required: 'El campo %{source} es requerido',
        greater: 'El campo %{source} debe ser selccionado o mayor a 0'
      }
    },
    'invoice-projections': {
      name: 'Factura Proyectada |||| Facturas Proyectadas',
      fields: {
        identifier: 'Identificador',
        period: 'Período',
        month: 'Mes',
        year: 'Año',
        project: 'Proyecto',
        currency: 'Moneda',
        amount: 'Monto',
        invoiceDetails: 'Detalle de factura',
        resource: 'Socie',
        role: 'Rol',
        seniority: 'Seniority',
        exchangeRate: 'Valor Hora',
        hours: 'Horas'
      }
    },
    projectTypes: {
      name: 'Tipo de Proyecto |||| Tipos de Proyectos',
      fields: {
        name: 'Nombre',
        allowSettingTotalAmount: 'Permitir asignar valor total de factura',
        allowSettingResourcesTime: 'Permitir asignar tiempos de trabajadoras',
        calculateTotalAmountWithResourcesTime:
          'Calcular monto total de factura con los tiempos de trabajadoras',
        rateRequired: 'Rate requerido en la creación de trabajadoras'
      }
    },
    'user-hours-register': {
      name: 'Registro de hora |||| Registro de horas',
      fields: {
        user: 'Usuarix',
        project: 'Proyecto',
        type: 'Tipo de horas',
        date: 'Fecha',
        startingDate: 'Fecha de Inicio',
        startingDate_gte: 'Fecha de Inicio Mayor A',
        startingDate_lte: 'Fecha de Inicio Menor A',
        endingDate: 'Fecha de Finalización',
        endingDate_gte: 'Fecha de Finalización Mayor A',
        endingDate_lte: 'Fecha de Finalización Menor A',
        hours: 'Horas',
        description: 'Descripción'
      },
      errors: {
        hoursRequired: 'Las horas son requeridas',
        dateRequired: 'La fecha es requerida',
        typeRequired: 'El tipo de horas es requerido',
        hoursGreaterThan0: 'La cantidad de horas debe ser mayor a 0',
        hoursLessThan24: 'La cantidad de horas debe ser menor o igual a 24',
        startingAfterEnding: 'La fecha inicial debe ser anterior a la final',
        endingBeforeStarting: 'La fecha final debe ser posterior a la inicial'
      }
    },
    'spent-hours-types': {
      name: 'Tipo de hora |||| Tipos de hora',
      fields: {
        name: 'Nombre'
      }
    },
    'project-technologies': {
      name: 'Tecnologías del Proyecto |||| Tecnologías de Proyectos',
      fields: {
        project: 'Proyecto',
        technologies: 'Tecnologías'
      }
    },
    'technology-categories': {
      name: 'Categoría de Tecnología |||| Categorías de Tecnologías',
      fields: {
        name: 'Nombre de Categoría'
      }
    },
    technologies: {
      name: 'Tecnología |||| Tecnologías',
      fields: {
        name: 'Nombre de Tecnología',
        category: 'Categoría de Tecnología',
        related: 'Tecnologías Relacionadas'
      }
    },
    'resource-technologies': {
      name:
        'Tecnología de las trabajadoras |||| Tecnologías de las trabajadoras',
      fields: {
        resource: 'Trabajadora',
        technology: 'Tecnología',
        technologyPercentage: 'Porcentaje de la Tecnología'
      },
      errors: {
        technologyPercentage:
          'La puntuación de la tecnología debe ser entre 1 y 5'
      }
    }
  }
};
