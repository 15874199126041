import React from 'react';
import {
  Create,
  Datagrid,
  Edit,
  EditButton,
  Filter,
  List,
  SearchInput,
  SimpleForm,
  TextField,
  TextInput
} from 'react-admin';

import EditingTitle from '../utils/editingTitle';

const RoleFilter = props => (
  <Filter {...props}>
    <SearchInput source="q" alwaysOn />
  </Filter>
);

const validateRoleCreation = values => {
  const errors = {};
  if (!values.name) {
    errors.name = ['The name is required'];
  }
  return errors;
};

export const RoleList = props => (
  <List filters={<RoleFilter />} {...props}>
    <Datagrid rowClick="edit">
      <TextField source="name" />
      <EditButton />
    </Datagrid>
  </List>
);

export const RoleEdit = props => (
  <Edit title={<EditingTitle.ForName {...props} />} {...props}>
    <SimpleForm>
      <TextInput source="name" />
    </SimpleForm>
  </Edit>
);

export const RoleCreate = props => (
  <Create {...props}>
    <SimpleForm validate={validateRoleCreation} redirect="/roles">
      <TextInput source="name" />
    </SimpleForm>
  </Create>
);
