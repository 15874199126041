import PropTypes from 'prop-types';
import React from 'react';
import {
  Button,
  ChipField,
  Create,
  Datagrid,
  Edit,
  EditButton,
  GET_ONE,
  List,
  ReferenceArrayField,
  ReferenceArrayInput,
  ReferenceField,
  ReferenceInput,
  ReferenceManyField,
  SelectArrayInput,
  SelectInput,
  Show,
  SimpleForm,
  SingleFieldList,
  TextField
} from 'react-admin';

import { DataProvider } from '../providers';

class ProjectTechnologiesTitle extends React.Component {
  constructor(props) {
    super(props);
    this.state = { project: null };
  }

  async componentDidMount() {
    const { record } = this.props;
    const project = await DataProvider(GET_ONE, `projects`, {
      id: record.project
    });
    this.setState({ project: project.data });
  }

  render() {
    const { project } = this.state;
    return <span>{project ? project.name : ''}</span>;
  }
}

export const ProjectTechnologiesList = props => (
  <List {...props}>
    <Datagrid rowClick="show">
      <ReferenceField source="project" reference="projects" linkType={false}>
        <TextField source="name" />
      </ReferenceField>
      <ReferenceArrayField source="technologies" reference="technologies">
        <SingleFieldList linkType={false}>
          <ChipField source="name" color="primary" />
        </SingleFieldList>
      </ReferenceArrayField>
      <EditButton />
    </Datagrid>
  </List>
);

export const ProjectTechnologiesCreate = props => {
  return (
    <Create {...props}>
      <SimpleForm redirect="show">
        <ReferenceInput source="project" reference="projects">
          <SelectInput optionText="name" />
        </ReferenceInput>
        <ReferenceArrayInput source="technologies" reference="technologies">
          <SelectArrayInput optionText="name" color="primary" />
        </ReferenceArrayInput>
        <Button />
      </SimpleForm>
    </Create>
  );
};

export const ProjectTechnologiesEdit = props => {
  return (
    <Edit {...props} title={<ProjectTechnologiesTitle {...props} />}>
      <SimpleForm>
        <ReferenceInput source="project" reference="projects">
          <SelectInput optionText="name" />
        </ReferenceInput>
        <ReferenceArrayInput source="technologies" reference="technologies">
          <SelectArrayInput optionText="name" />
        </ReferenceArrayInput>
      </SimpleForm>
    </Edit>
  );
};

export const ProjectTechnologiesShow = props => {
  return (
    <Show {...props} title={<ProjectTechnologiesTitle {...props} />}>
      <SimpleForm>
        <ReferenceField source="project" reference="projects" linkType={false}>
          <TextField source="name" />
        </ReferenceField>
        <ReferenceManyField
          reference="technologies"
          source="technologies"
          target="_id"
        >
          <SingleFieldList linkType={false}>
            <ChipField source="name" color="primary" />
          </SingleFieldList>
        </ReferenceManyField>
      </SimpleForm>
    </Show>
  );
};

ProjectTechnologiesTitle.propTypes = {
  record: PropTypes.instanceOf(Object).isRequired
};
