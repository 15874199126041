/* eslint-disable no-shadow */
/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import { crudCreate, SaveButton, Toolbar } from 'react-admin';
import { connect } from 'react-redux';
import compose from 'recompose/compose';

import {
  resetInvoiceDate,
  resetInvoiceDetails
} from '../../store/actions/invoiceAction';
import conversions from '../../utils/conversions';

const mapStateToProps = state => ({
  allResources: state.admin.resources,
  invoiceDetails: state.invoice.invoiceDetails,
  invoice: state.invoice,
  invoiceDate: state.invoice.invoiceDate
});

const mapDispatchToProps = dispatch => {
  return {
    resetDetails: payload => {
      dispatch(resetInvoiceDetails(payload));
    },
    resetDate: payload => {
      dispatch(resetInvoiceDate(payload));
    }
  };
};

const enhance = compose(connect(mapStateToProps, mapDispatchToProps));

const saveInvoice = (
  values,
  invoiceDetails,
  invoiceDate,
  basePath,
  redirectTo,
  allResources
) => {
  const { currency } = allResources.projects.data[values.project] || {
    currency: '5df24c4d1cfcfc00cfa957da'
  };
  const reducer = (acc, currentValue) => {
    return acc + currentValue.rate * currentValue.hours;
  };
  const amount = values.amount
    ? values.amount
    : invoiceDetails.reduce(reducer, 0);
  const period = conversions.formatDate(invoiceDate);
  const invoice = {
    identifier: values.identifier,
    period,
    project: values.project,
    currency,
    exchangeRate: values.exchangeRate,
    amount,
    invoiceDetails
  };
  return crudCreate('invoices', invoice, basePath, redirectTo);
};

class SaveInvoiceButtonView extends Component {
  componentWillUnmount() {
    const { resetDetails, resetDate } = this.props;
    resetDetails();
    resetDate();
  }

  handleClick = () => {
    const {
      basePath,
      handleSubmit,
      redirect,
      saveInvoice,
      invoiceDetails,
      invoiceDate,
      allResources
    } = this.props;
    return handleSubmit(values => {
      return saveInvoice(
        values,
        invoiceDetails,
        invoiceDate,
        basePath,
        redirect,
        allResources
      );
    });
  };

  render() {
    const { handleSubmitWithRedirect, saveInvoice, ...props } = this.props;

    return (
      <SaveButton handleSubmitWithRedirect={this.handleClick} {...props} />
    );
  }
}

const SaveInvoiceButton = connect(undefined, {
  saveInvoice
})(SaveInvoiceButtonView);

const SaveInvoicesToolbar = props => (
  <Toolbar {...props}>
    <SaveInvoiceButton {...props} />
  </Toolbar>
);

export default enhance(SaveInvoicesToolbar);
