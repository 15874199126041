import PropTypes, { any } from 'prop-types';
import React, { Children, cloneElement, isValidElement } from 'react';

const processChildren = (children, props) => {
  const childrenWithProps = Children.map(children, child => {
    if (isValidElement(child)) {
      return cloneElement(child, { ...props });
    }
    return child;
  });

  return childrenWithProps;
};

const EmptyDataGuard = props => {
  const { children, empty, ...restOfProps } = props;
  const containsData =
    restOfProps.data && Object.keys(restOfProps.data).length > 0;
  const componentToRender = containsData ? children : empty;
  return <>{processChildren(componentToRender, restOfProps)}</>;
};

EmptyDataGuard.defaultProps = {
  children: {},
  empty: {}
};

EmptyDataGuard.propTypes = {
  children: PropTypes.objectOf(any),
  empty: PropTypes.objectOf(any)
};

export default EmptyDataGuard;
