/* eslint react/prop-types: 0 */
import TableCell from '@material-ui/core/TableCell';
import React from 'react';

import conversions from '../utils/conversions';

const locale = 'es-AR';
const currency = 'ARS';

const properties = {
  style: 'currency',
  currency,
  minimumFractionDigits: 2,
  maximumFractionDigits: 2
};

const AmountTableCell = ({ source, record = {} }) => (
  <TableCell>
    {new Intl.NumberFormat(locale, properties).format(
      conversions.format(record[source])
    )}
  </TableCell>
);

const AmountField = ({ source, record = {} }) => (
  <span>
    {record[source] !== null && record[source] !== undefined
      ? new Intl.NumberFormat(locale, properties).format(
          conversions.format(record[source])
        )
      : '-'}
  </span>
);

const PeriodField = ({ source, record = {} }) => {
  const splitDate = record[source].split('-');
  return (
    <span>
      {splitDate[0]}-{splitDate[1]}
    </span>
  );
};

PeriodField.defaultProps = {
  addLabel: true
};

AmountField.defaultProps = {
  addLabel: true
};

export { AmountField, AmountTableCell, PeriodField };
