import { IconButton } from '@material-ui/core';
import { Clear } from '@material-ui/icons';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import {
  Create,
  Datagrid,
  DateField,
  DateInput,
  Edit,
  EditButton,
  Filter,
  List,
  LongTextInput,
  NumberField,
  NumberInput,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextField,
  withTranslate
} from 'react-admin';

import conversions from '../utils/conversions';
import EditingTitle from '../utils/editingTitle';

const weekStart = moment()
  .startOf('week')
  .add(1, 'day')
  .format('YYYY-MM-DD');
const weekEnd = moment()
  .endOf('week')
  .subtract(1, 'day')
  .format('YYYY-MM-DD');

const validateUserHoursRegisterCreation = translate => values => {
  const errors = {};
  if (!values.type) {
    errors.type = [
      translate('resources.user-hours-register.errors.typeRequired')
    ];
  }
  if (!values.hours) {
    errors.hours = [
      translate('resources.user-hours-register.errors.hoursRequired')
    ];
  } else if (values.hours < 0) {
    errors.hours = [
      translate('resources.user-hours-register.errors.hoursGreaterThan0')
    ];
  }
  if (!values.startingDate) {
    errors.startingDate = [
      translate('resources.user-hours-register.errors.dateRequired')
    ];
  }
  if (!values.endingDate) {
    errors.endingDate = [
      translate('resources.user-hours-register.errors.dateRequired')
    ];
  }
  if (
    values.startingDate &&
    values.endingDate &&
    new Date(values.startingDate) > new Date(values.endingDate)
  ) {
    errors.startingDate = [
      translate('resources.user-hours-register.errors.startingAfterEnding')
    ];
    errors.endingDate = [
      translate('resources.user-hours-register.errors.endingBeforeStarting')
    ];
  }
  return errors;
};

const TIME_ZONE = 'UTC';

export const UserHoursRegisterList = props => (
  <List filters={<UserHoursRegisterFilter />} {...props}>
    <Datagrid rowClick="show">
      <DateField source="startingDate" options={{ timeZone: TIME_ZONE }} />
      <DateField source="endingDate" options={{ timeZone: TIME_ZONE }} />
      <ReferenceField source="user" reference="users" linkType={false}>
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField source="project" reference="projects" linkType={false}>
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField
        source="type"
        reference="spent-hours-types"
        linkType={false}
      >
        <TextField source="name" />
      </ReferenceField>
      <NumberField source="hours" />
      <EditButton />
    </Datagrid>
  </List>
);

// eslint-disable-next-line react/prop-types
export const UserHoursRegisterEdit = withTranslate(
  ({ permissions, ...props }) => (
    <Edit title={<EditingTitle.ForHoursRegister {...props} />} {...props}>
      <SimpleForm
        redirect="list"
        validate={validateUserHoursRegisterCreation(props.translate)}
      >
        <DateInput source="startingDate" format={conversions.formatDate} />
        <DateInput source="endingDate" format={conversions.formatDate} />
        {!Array.isArray(permissions) ? (
          <ReferenceInput source="user" reference="users">
            <SelectInput optionText="name" />
          </ReferenceInput>
        ) : (
          ''
        )}
        <ReferenceInput
          source="project"
          reference="projects"
          allowempty
          resettable
          perPage={100}
        >
          <SelectInput optionText="name" />
        </ReferenceInput>
        <ReferenceInput source="type" reference="spent-hours-types">
          <SelectInput optionText="name" />
        </ReferenceInput>
        <NumberInput source="hours" step={0.5} />
        <LongTextInput source="description" resettable />
      </SimpleForm>
    </Edit>
  )
);

// eslint-disable-next-line react/prop-types
export const UserHoursRegisterCreate = withTranslate(
  ({ permissions, ...props }) => (
    <Create {...props}>
      <SimpleForm
        redirect="list"
        validate={validateUserHoursRegisterCreation(props.translate)}
      >
        <DateInput
          source="startingDate"
          defaultValue={weekStart}
          format={conversions.formatDate}
        />
        <DateInput
          source="endingDate"
          defaultValue={weekEnd}
          format={conversions.formatDate}
        />
        {!Array.isArray(permissions) ? (
          <ReferenceInput source="user" reference="users">
            <SelectInput optionText="name" />
          </ReferenceInput>
        ) : (
          ''
        )}
        <ReferenceInput
          source="project"
          reference="projects"
          allowempty
          resettable
          perPage={100}
        >
          <SelectInput optionText="name" />
        </ReferenceInput>
        <ReferenceInput source="type" reference="spent-hours-types">
          <SelectInput optionText="name" />
        </ReferenceInput>
        <NumberInput source="hours" step={0.5} />
        <LongTextInput source="description" resettable />
      </SimpleForm>
    </Create>
  )
);

const UserHoursRegisterFilter = props => (
  <div>
    <Filter {...props}>
      <DateInput source="startingDate_gte" alwaysOn allowEmpty />
      <DateInput source="endingDate_lte" alwaysOn allowEmpty />
      <IconButton onClick={() => props.setFilters({})} alwaysOn color="primary">
        <Clear />
      </IconButton>
    </Filter>
    <Filter {...props}>
      <ReferenceInput
        source="project"
        reference="projects"
        allowempty
        alwaysOn
        perPage={100}
      >
        <SelectInput optionText="name" resettable />
      </ReferenceInput>
      <ReferenceInput source="user" reference="users" allowempty alwaysOn>
        <SelectInput optionText="name" resettable />
      </ReferenceInput>
      <ReferenceInput
        source="type"
        reference="spent-hours-types"
        allowempty
        alwaysOn
      >
        <SelectInput optionText="name" resettable />
      </ReferenceInput>
    </Filter>
  </div>
);

UserHoursRegisterFilter.propTypes = {
  setFilters: PropTypes.func.isRequired
};
