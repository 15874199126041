/* eslint-disable max-classes-per-file */
import PropTypes from 'prop-types';
import React from 'react';
import {
  ArrayField,
  AutocompleteInput,
  Create,
  Datagrid,
  DeleteButton,
  Edit,
  EditButton,
  Filter,
  FormDataConsumer,
  GET_LIST,
  GET_ONE,
  List,
  NumberField,
  NumberInput,
  ReferenceField,
  ReferenceInput,
  SearchInput,
  SelectInput,
  Show,
  ShowButton,
  SimpleForm,
  SimpleShowLayout,
  TextField,
  TextInput,
  withTranslate
} from 'react-admin';
import { connect } from 'react-redux';
import compose from 'recompose/compose';

import EmptyDataGuard from '../components/EmptyDataguard/EmptyDataGuard';
import { AmountField, PeriodField } from '../components/fields';
import EmptyRow from '../components/invoice-empty-row/EmptyRow';
import CurrencyField from '../components/invoice-projections/currencyField';
import InvoiceDetails from '../components/invoice-projections/invoiceDetails';
import SaveInvoicesToolbar from '../components/invoice-projections/SaveToolbar';
import { DataProvider } from '../providers';
import { loadInvoiceDate } from '../store/actions/invoiceAction';
import { months, years } from '../utils/calendarize';
import conversions from '../utils/conversions';
import EditingTitle from '../utils/editingTitle';

const validateInvoiceProjectionCreation = (
  resources,
  exchangeRates
) => values => {
  const errors = {};
  if (!values.identifier) {
    errors.identifier = ['The identifier is required'];
  }
  if (!(values.month + 1)) {
    errors.month = ['The month is required'];
  }
  if (!values.year) {
    errors.year = ['The year is required'];
  }
  if (!values.project) {
    errors.project = ['The project is required'];
  }
  if (values.exchangeRate < 1) {
    errors.exchangeRate = ['The exchange rate should be greater than 0'];
  }
  if (values.project) {
    const { currency } = resources.projects.data[values.project];
    const exchageRateExist = exchangeRates.data.some(
      er => er.currency === currency
    );
    if (!values.exchangeRate && !exchageRateExist) {
      errors.exchangeRate = [
        'The project currency does not have an exchange rate'
      ];
    }
  }
  return errors;
};

const InvoiceProjectionFilter = props => (
  <Filter {...props}>
    <SearchInput source="q" alwaysOn />
    <ReferenceInput source="project" reference="projects" allowempty alwaysOn>
      <SelectInput optionText="name" />
    </ReferenceInput>
    <ReferenceInput
      source="currency"
      reference="currencies"
      allowempty
      alwaysOn
    >
      <SelectInput optionText="name" />
    </ReferenceInput>
  </Filter>
);
const validateInvoiceProjectionEdit = () => values => {
  const errors = {};
  if (!values.identifier) {
    errors.identifier = ['The identifier is required'];
  }
  if (!(values.month + 1)) {
    errors.month = ['The month is required'];
  }
  if (!values.year) {
    errors.year = ['The year is required'];
  }
  if (!values.project) {
    errors.project = ['The project is required'];
  }
  if (
    values.exchangeRate !== null &&
    values.exchangeRate !== undefined &&
    values.exchangeRate < 1
  ) {
    errors.exchangeRate = ['The exchange rate should be greater than 0'];
  }
  if (!values.period) {
    errors.period = ['The period is required'];
  }
  return errors;
};

export const InvoiceProjectionList = withTranslate(props => (
  <List filters={<InvoiceProjectionFilter />} {...props}>
    <Datagrid rowClick="show">
      <TextField source="identifier" />
      <ReferenceField
        source="project._id"
        reference="projects"
        label={props.translate('resources.invoice-projections.fields.project')}
        sortBy="project.name"
        linkType={false}
      >
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField
        source="project.client._id"
        reference="clients"
        label={props.translate('resources.projects.fields.client')}
        sortBy="project.client.name"
        linkType={false}
      >
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField source="currency" reference="currencies" linkType={false}>
        <TextField source="name" />
      </ReferenceField>
      <PeriodField source="period" />
      <AmountField source="amount" />
      <EditButton />
      <ShowButton />
    </Datagrid>
  </List>
));

export const InvoiceProjectionShow = props => {
  return (
    <Show title={<EditingTitle.ForIdentifier {...props} />} {...props}>
      <SimpleShowLayout>
        <TextField source="identifier" />
        <PeriodField source="period" />
        <ReferenceField source="project" reference="projects">
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField source="currency" reference="currencies">
          <TextField source="name" />
        </ReferenceField>
        <AmountField label="Exchange Rate" source="exchangeRate" />
        <AmountField source="amount" />
        <ArrayField source="invoiceDetailProjections">
          <EmptyDataGuard
            empty={<EmptyRow text="components.emptyRow.noInvoiceDetails" />}
          >
            <Datagrid>
              <ReferenceField source="resource" reference="resources">
                <ReferenceField source="user" reference="users">
                  <TextField source="name" />
                </ReferenceField>
              </ReferenceField>
              <ReferenceField
                label="Role"
                source="resource"
                reference="resources"
              >
                <ReferenceField source="role" reference="roles">
                  <TextField source="name" />
                </ReferenceField>
              </ReferenceField>
              <ReferenceField
                label="Seniority"
                source="resource"
                reference="resources"
              >
                <ReferenceField source="seniority" reference="seniorities">
                  <TextField source="name" />
                </ReferenceField>
              </ReferenceField>
              <ReferenceField
                label="Rate"
                source="resource"
                reference="resources"
              >
                <AmountField source="rate" />
              </ReferenceField>
              <NumberField source="hours" />
            </Datagrid>
          </EmptyDataGuard>
        </ArrayField>
        <DeleteButton />
      </SimpleShowLayout>
    </Show>
  );
};

const projectTypeMatches = (state, matcher) => {
  const returnVale =
    state.selectedProjectType && matcher(state.selectedProjectType);
  return returnVale;
};

const allowSettingResourcesTime = projectType =>
  projectType.allowSettingResourcesTime;
const allowSettingTotalAmount = projectType =>
  projectType.allowSettingTotalAmount;

class InvCreate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      exchangeRates: { data: [] }
    };
  }

  async componentDidMount() {
    const exchangeRates = await DataProvider(GET_LIST, 'exchange-rates', {});
    this.setState({ exchangeRates });
  }

  render() {
    const { props, state } = this;
    const updateInvoiceDate = type => (event, value) => {
      props.loadDate({ invoiceDate: { [type]: value } });
    };
    const updateProject = (event, value) => {
      const selectedProject = value;
      if (selectedProject) {
        DataProvider(GET_ONE, `projects/${selectedProject}/projectTypes`, {
          id: -1
        }).then(projectTypeData =>
          this.setState({ selectedProjectType: projectTypeData.data })
        );
      }
    };
    return (
      <Create {...props}>
        <SimpleForm
          validate={validateInvoiceProjectionCreation(
            props.resources,
            state.exchangeRates
          )}
          redirect="/invoice-projections"
          toolbar={<SaveInvoicesToolbar {...props} />}
        >
          <TextInput source="identifier" />
          <SelectInput
            source="month"
            onChange={updateInvoiceDate('month')}
            choices={months}
          />
          <SelectInput
            source="year"
            onChange={updateInvoiceDate('year')}
            choices={years}
          />
          <ReferenceInput
            source="project"
            reference="projects"
            onChange={updateProject}
          >
            <AutocompleteInput source="name" />
          </ReferenceInput>
          <FormDataConsumer>
            {({ formData }) =>
              formData.project && (
                <div>
                  <CurrencyField
                    source="currencies"
                    project={formData.project}
                  />
                  <NumberInput
                    format={conversions.format}
                    parse={conversions.parse}
                    source="exchangeRate"
                  />
                </div>
              )
            }
          </FormDataConsumer>
          <FormDataConsumer>
            {({ formData }) => {
              return (
                formData.project &&
                projectTypeMatches(this.state, allowSettingTotalAmount) && (
                  <NumberInput
                    format={conversions.format}
                    parse={conversions.parse}
                    source="amount"
                  />
                )
              );
            }}
          </FormDataConsumer>
          <FormDataConsumer>
            {({ formData }) => {
              return (
                formData.project &&
                projectTypeMatches(this.state, allowSettingResourcesTime) && (
                  <InvoiceDetails
                    source="resources"
                    project={formData.project}
                  />
                )
              );
            }}
          </FormDataConsumer>
        </SimpleForm>
      </Create>
    );
  }
}

// eslint-disable-next-line
class InvProjectionsEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      exchangeRates: { data: [] }
    };
  }

  async componentDidMount() {
    const exchangeRates = await DataProvider(GET_LIST, 'exchange-rates', {});
    this.setState({ exchangeRates });
  }

  render() {
    const { props, state } = this;
    const updateInvoiceDate = type => (event, value) => {
      props.loadDate({ invoiceDate: { [type]: value } });
    };
    const updateProject = (event, value) => {
      const selectedProject = value;
      if (selectedProject) {
        DataProvider(GET_ONE, `projects/${selectedProject}/projectTypes`, {
          id: -1
        }).then(projectTypeData =>
          this.setState({ selectedProjectType: projectTypeData.data })
        );
      }
    };

    return (
      <Edit title={<EditingTitle.ForIdentifier {...props} />} {...props}>
        <SimpleForm
          validate={validateInvoiceProjectionEdit(
            props.resources,
            state.exchangeRates
          )}
          redirect="/invoice-projections"
          toolbar={<SaveInvoicesToolbar {...props} mode="edit" />}
        >
          <TextInput source="identifier" />
          <SelectInput
            source="month"
            onChange={updateInvoiceDate('month')}
            choices={months}
          />
          <SelectInput
            source="year"
            onChange={updateInvoiceDate('year')}
            choices={years}
          />
          <ReferenceInput
            source="project"
            reference="projects"
            onChange={updateProject}
          >
            <AutocompleteInput source="name" />
          </ReferenceInput>
          <FormDataConsumer>
            {({ formData }) =>
              formData.project && (
                <div>
                  <CurrencyField
                    source="currencies"
                    // eslint-disable-next-line no-underscore-dangle
                    project={formData.project._id}
                  />
                  <NumberInput
                    format={conversions.format}
                    parse={conversions.parse}
                    source="exchangeRate"
                  />
                </div>
              )
            }
          </FormDataConsumer>
          <FormDataConsumer>
            {({ formData }) => {
              const { selectedProjectType } = this.state;
              if (formData.project && !selectedProjectType) {
                // eslint-disable-next-line no-underscore-dangle
                updateProject(null, formData.project);
              }
              return (
                formData.project &&
                projectTypeMatches(this.state, allowSettingTotalAmount) && (
                  <NumberInput
                    format={conversions.format}
                    parse={conversions.parse}
                    source="amount"
                  />
                )
              );
            }}
          </FormDataConsumer>
          <FormDataConsumer>
            {({ formData }) => {
              return (
                formData.project &&
                formData.invoiceDetailProjections &&
                formData.invoiceDetailProjections.length > 0 &&
                projectTypeMatches(this.state, allowSettingResourcesTime) && (
                  <InvoiceDetails
                    source="resources"
                    hours={formData.invoiceDetailProjections[0].hours}
                    project={formData.project}
                    invoiceProjection={props.id}
                  />
                )
              );
            }}
          </FormDataConsumer>
        </SimpleForm>
      </Edit>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    loadDate: payload => {
      dispatch(loadInvoiceDate(payload));
    }
  };
};

const mapStateToProps = store => {
  return {
    resources: store.admin.resources
  };
};

const enhance = compose(connect(mapStateToProps, mapDispatchToProps));

export const InvoiceProjectionCreate = enhance(InvCreate);
export const InvoiceProjectionEdit = enhance(InvProjectionsEdit);

InvoiceProjectionShow.propTypes = {
  id: PropTypes.string.isRequired
};

InvCreate.propTypes = {
  loadDate: PropTypes.func.isRequired,
  resources: PropTypes.instanceOf(Object).isRequired
};

InvProjectionsEdit.propTypes = {
  loadDate: PropTypes.func.isRequired,
  resources: PropTypes.instanceOf(Object).isRequired,
  id: PropTypes.string.isRequired
};
