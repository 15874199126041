import ActionTypes from '../constants/invoice';
import createReducer from '../lib/helpers';

const initialState = {
  invoiceDetails: [],
  invoiceDate: null
};

export default createReducer(initialState, {
  [ActionTypes.LOAD_INVOICE_DETAIL](store, { payload }) {
    const { invoiceDetails } = store;
    const updatedInvoiceDetails = invoiceDetails.map(invD => {
      if (invD.resource === payload.invoiceDetail.resource) {
        return { ...payload.invoiceDetail, id: invD.id };
      }
      return { ...invD };
    });
    return { ...store, invoiceDetails: updatedInvoiceDetails };
  },
  [ActionTypes.UPDATE_INVOICE_DETAILS](invoice, { payload }) {
    if (invoice.invoiceDetails.length === 0) {
      const mergedDetails = payload.resources.map(res => {
        const associatedInvoiceDetail = payload.details.find(
          det => det.resource === res.id
        );
        let id = null;
        let hours = null;
        if (associatedInvoiceDetail) {
          id = associatedInvoiceDetail.id;
          hours = associatedInvoiceDetail.hours;
        }
        const detail = {
          resource: res.id,
          hours,
          rate: res.rate,
          id
        };
        return detail;
      });
      return { ...invoice, invoiceDetails: mergedDetails };
    }
    const invoiceDetailsReduced = invoice.invoiceDetails.filter(detail =>
      // eslint-disable-next-line no-underscore-dangle
      payload.resources.map(res => res._id).includes(detail.resource)
    );
    return { ...invoice, invoiceDetails: invoiceDetailsReduced };
  },
  [ActionTypes.RESET_INVOICE_DETAILS](store) {
    const { invoiceDetails, ...storeProps } = store;
    return { storeProps, invoiceDetails: initialState.invoiceDetails };
  },
  [ActionTypes.LOAD_INVOICE_DATE](store, { payload }) {
    const { invoiceDate, ...storeProps } = store;
    return { ...storeProps, invoiceDate: payload.invoiceDate };
  },
  [ActionTypes.RESET_INVOICE_DATE](invoice) {
    return { ...invoice, ...initialState.invoiceDate };
  }
});
