import React from 'react';
import {
  Create,
  Datagrid,
  Edit,
  EditButton,
  List,
  NumberInput,
  SelectInput,
  SimpleForm
} from 'react-admin';

import { AmountField, PeriodField } from '../components/fields';
import EditMonthlyExpensesToolbar from '../components/monthly-expenses/EditToolbar';
import SaveMonthlyExpensesToolbar from '../components/monthly-expenses/SaveToolbar';
import { months, years } from '../utils/calendarize';
import conversions from '../utils/conversions';
import EditingTitle from '../utils/editingTitle';

const validateMonthlyExpenseCreation = values => {
  const errors = {};
  if (!(values.month + 1)) {
    errors.month = ['The month is required'];
  }
  return errors;
};

export const MonthlyExpenseList = props => (
  <List {...props}>
    <Datagrid rowClick="edit">
      <PeriodField source="period" />
      <AmountField source="amount" />
      <EditButton />
    </Datagrid>
  </List>
);

export const MonthlyExpenseEdit = props => (
  <Edit title={<EditingTitle.ForMonthAndYear {...props} />} {...props}>
    <SimpleForm
      redirect="/monthly-expenses"
      toolbar={<EditMonthlyExpensesToolbar {...props} />}
    >
      <SelectInput
        source="month"
        choices={months}
        optionText="name"
        optionValue="id"
      />
      <SelectInput
        source="year"
        choices={years}
        optionText="name"
        optionValue="id"
      />
      <NumberInput
        source="amount"
        format={conversions.format}
        parse={conversions.parse}
      />
    </SimpleForm>
  </Edit>
);

export const MonthlyExpenseCreate = props => (
  <Create {...props}>
    <SimpleForm
      validate={validateMonthlyExpenseCreation}
      redirect="/monthly-expenses"
      toolbar={<SaveMonthlyExpensesToolbar {...props} />}
    >
      <SelectInput source="month" choices={months} />
      <SelectInput source="year" choices={years} />
      <NumberInput
        source="amount"
        format={conversions.format}
        parse={conversions.parse}
      />
    </SimpleForm>
  </Create>
);
