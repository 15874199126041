import React from 'react';
import { Layout } from 'react-admin';

import MyAppBar from './AppBar';
import defaultTheme from './defaultTheme';

const MyLayout = props => <Layout {...props} appBar={MyAppBar} />;

MyLayout.defaultProps = {
  theme: defaultTheme
};

export default MyLayout;
