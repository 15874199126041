import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withTranslate } from 'react-admin';
import { connect } from 'react-redux';
import compose from 'recompose/compose';

import {
  loadInvoiceDetail,
  updateInvoiceDetails
} from '../../store/actions/invoiceAction';
import EmptyRow from '../invoice-empty-row/EmptyRow';
import {
  buildResourcesRows,
  getInvoiceResourcesAndUpdateDetails
} from '../invoiceDetails.logic';

const mapDispatchToProps = dispatch => {
  return {
    loadDetail: payload => {
      dispatch(loadInvoiceDetail(payload));
    },
    updateDetails: payload => {
      dispatch(updateInvoiceDetails(payload));
    }
  };
};

const mapStateToProps = state => ({
  invoiceDate: state.invoice.invoiceDate
});

const enhance = compose(connect(mapStateToProps, mapDispatchToProps));

class InvoiceDetails extends Component {
  constructor(props) {
    super(props);
    this.state = { invoiceResources: [] };
  }

  componentDidMount() {
    const { project, updateDetails, invoiceProjection } = this.props;
    if (project) {
      getInvoiceResourcesAndUpdateDetails(
        this,
        project,
        updateDetails,
        invoiceProjection
      );
    }
  }

  componentDidUpdate(prevProps) {
    const {
      project,
      invoiceDate,
      updateDetails,
      invoiceProjection
    } = this.props;
    if (
      (prevProps.project && prevProps.project !== project) ||
      (prevProps.invoiceDate &&
        (prevProps.invoiceDate.month !== invoiceDate.month ||
          prevProps.invoiceDate.year !== invoiceDate.year))
    ) {
      getInvoiceResourcesAndUpdateDetails(
        this,
        project,
        updateDetails,
        invoiceProjection
      );
    }
  }

  render() {
    // eslint-disable-next-line react/prop-types
    const { loadDetail, project, hours } = this.props;
    const { invoiceResources = [] } = this.state;
    const rows = buildResourcesRows(invoiceResources, loadDetail, hours);

    return rows.length > 0 ? (
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>User </TableCell>
            <TableCell>Seniority </TableCell>
            <TableCell>Role </TableCell>
            <TableCell>Rate </TableCell>
            <TableCell>Hours </TableCell>
          </TableRow>
        </TableHead>{' '}
        <TableBody>{rows} </TableBody>
      </Table>
    ) : (
      <EmptyRow
        addResourcesButton
        projectId={project}
        text="components.emptyRow.noResources"
      />
    );
  }
}

InvoiceDetails.propTypes = {
  loadDetail: PropTypes.func.isRequired,
  project: PropTypes.string.isRequired,
  invoiceDate: PropTypes.instanceOf(Object).isRequired,
  updateDetails: PropTypes.func.isRequired,
  invoiceProjection: PropTypes.string
};

InvoiceDetails.defaultProps = {
  invoiceProjection: null
};

export default enhance(withTranslate(InvoiceDetails));
