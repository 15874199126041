import PropTypes from 'prop-types';
import React from 'react';
import {
  ArrayField,
  Create,
  Datagrid,
  DeleteButton,
  Filter,
  FormDataConsumer,
  GET_LIST,
  List,
  NumberField,
  NumberInput,
  ReferenceField,
  ReferenceInput,
  SearchInput,
  SelectInput,
  Show,
  ShowButton,
  SimpleForm,
  SimpleShowLayout,
  TextField,
  TextInput,
  withTranslate
} from 'react-admin';
import { connect } from 'react-redux';
import compose from 'recompose/compose';

import EmptyDataGuard from '../components/EmptyDataguard/EmptyDataGuard';
import { AmountField, PeriodField } from '../components/fields';
import EmptyRow from '../components/invoice-empty-row/EmptyRow';
import CurrencyField from '../components/invoices/currencyField';
import SaveInvoicesToolbar from '../components/invoices/SaveToolbar';
import { DataProvider } from '../providers';
import conversions from '../utils/conversions';
import DatePickerComponent from '../utils/DatePicker';
import EditingTitle from '../utils/editingTitle';

const validateInvoiceCreation = (
  resources,
  exchangeRates,
  { translate }
) => values => {
  const errors = {};
  if (!values.client) {
    errors.client = translate(`resources.invoices.validation.required`, {
      source: translate(`resources.invoices.fields.client`)
    });
  }
  if (!values.project) {
    errors.project = translate(`resources.invoices.validation.required`, {
      source: translate(`resources.invoices.fields.project`)
    });
  }
  if (
    values.exchangeRate !== null &&
    values.exchangeRate !== undefined &&
    values.exchangeRate < 1
  ) {
    errors.exchangeRate = ['The exchange rate should be greater than 0'];
  }
  if (values.project) {
    const { currency } = resources.projects.data[values.project] || {
      currency: '5df24c4d1cfcfc00cfa957da'
    };
    const exchageRateExist = exchangeRates.data.some(
      er => er.currency === currency
    );
    if (!values.exchangeRate && !exchageRateExist) {
      errors.exchangeRate = [
        'The project currency does not have an exchange rate'
      ];
    }
  }
  if (!values.amount) {
    errors.amount = translate(`resources.invoices.validation.required`, {
      source: translate(`resources.invoices.fields.amount`)
    });
  }
  if (
    values.amount !== null &&
    values.amount !== undefined &&
    values.amount < 1
  ) {
    errors.amount = translate(`resources.invoices.validation.greater`, {
      source: translate(`resources.invoices.fields.amount`)
    });
  }
  return errors;
};

const InvoiceFilter = props => (
  <Filter {...props}>
    <SearchInput source="q" alwaysOn />
    <ReferenceInput source="project" reference="projects" allowempty alwaysOn>
      <SelectInput optionText="name" />
    </ReferenceInput>
    <ReferenceInput
      source="currency"
      reference="currencies"
      allowempty
      alwaysOn
    >
      <SelectInput optionText="name" />
    </ReferenceInput>
  </Filter>
);

export const InvoiceList = withTranslate(props => (
  <List filters={<InvoiceFilter />} {...props}>
    <Datagrid rowClick="show">
      <ReferenceField
        source="project._id"
        reference="projects"
        label={props.translate('resources.invoices.fields.project')}
        sortBy="project.name"
        linkType={false}
      >
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField
        source="project.client._id"
        reference="clients"
        label={props.translate('resources.projects.fields.client')}
        sortBy="project.client.name"
        linkType={false}
      >
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField source="currency" reference="currencies" linkType={false}>
        <TextField source="name" />
      </ReferenceField>
      <PeriodField source="period" />
      <AmountField source="amount" />
      <ShowButton />
    </Datagrid>
  </List>
));

export const InvoiceShow = props => {
  return (
    <Show title={<EditingTitle.ForIdentifier {...props} />} {...props}>
      <SimpleShowLayout>
        <TextField source="identifier" />
        <PeriodField source="period" />
        <ReferenceField source="project" reference="projects">
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField source="currency" reference="currencies">
          <TextField source="name" />
        </ReferenceField>
        <AmountField label="Exchange Rate" source="exchangeRate" />
        <AmountField source="amount" />
        <ArrayField source="invoiceDetails">
          <EmptyDataGuard
            empty={<EmptyRow text="components.emptyRow.noInvoiceDetails" />}
          >
            <Datagrid>
              <ReferenceField source="resource" reference="resources">
                <ReferenceField source="user" reference="users">
                  <TextField source="name" />
                </ReferenceField>
              </ReferenceField>
              <ReferenceField
                label="Role"
                source="resource"
                reference="resources"
              >
                <ReferenceField source="role" reference="roles">
                  <TextField source="name" />
                </ReferenceField>
              </ReferenceField>
              <ReferenceField
                label="Seniority"
                source="resource"
                reference="resources"
              >
                <ReferenceField source="seniority" reference="seniorities">
                  <TextField source="name" />
                </ReferenceField>
              </ReferenceField>
              <ReferenceField
                label="Rate"
                source="resource"
                reference="resources"
              >
                <AmountField source="rate" />
              </ReferenceField>
              <NumberField source="hours" />
            </Datagrid>
          </EmptyDataGuard>
        </ArrayField>
        <DeleteButton />
      </SimpleShowLayout>
    </Show>
  );
};

class InvCreate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      exchangeRates: { data: [] },
      selectedProjects: []
    };
  }

  async componentDidMount() {
    const exchangeRates = await DataProvider(GET_LIST, 'exchange-rates', {});
    this.setState({ exchangeRates });
  }

  render() {
    const { props, state } = this;
    const getProjectToClient = (event, value) => {
      const selectedClient = value;
      return DataProvider(GET_LIST, `projects?client=${selectedClient}`, {
        id: -1
      }).then(projectsData =>
        this.setState({ selectedProjects: projectsData.data })
      );
    };

    return (
      <Create {...props}>
        <SimpleForm
          validate={validateInvoiceCreation(
            props.resources,
            state.exchangeRates,
            props
          )}
          redirect="/invoices"
          toolbar={<SaveInvoicesToolbar {...props} />}
        >
          <TextInput source="identifier" />
          <DatePickerComponent source="period" />
          <ReferenceInput
            source="client"
            reference="clients"
            onChange={getProjectToClient}
            perPage={100}
          >
            <SelectInput optionText="name" />
          </ReferenceInput>
          <SelectInput
            source="project"
            choices={state.selectedProjects}
            optionText={record =>
              `${record.name} (${conversions.formatDate(
                record.startingDate
              )} - ${conversions.formatDate(record.endingDate)})`
            }
          />
          <FormDataConsumer>
            {({ formData }) =>
              formData.project && (
                <div>
                  <CurrencyField
                    source="currencies"
                    project={formData.project}
                  />
                  <NumberInput
                    format={conversions.format}
                    parse={conversions.parse}
                    label={props.translate(
                      `resources.invoices.fields.exchangeRate`
                    )}
                    source="exchangeRate"
                  />
                </div>
              )
            }
          </FormDataConsumer>
          <NumberInput
            format={conversions.format}
            parse={conversions.parse}
            source="amount"
          />
        </SimpleForm>
      </Create>
    );
  }
}

const mapStateToProps = store => {
  return {
    resources: store.admin.resources
  };
};

const enhance = compose(connect(mapStateToProps));

export const InvoiceCreate = enhance(withTranslate(InvCreate));

InvoiceShow.propTypes = {
  id: PropTypes.string.isRequired
};

InvCreate.propTypes = {
  loadDate: PropTypes.func.isRequired,
  resources: PropTypes.instanceOf(Object).isRequired,
  translate: PropTypes.func.isRequired
};
